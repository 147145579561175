import React from "react";
import style from "./index.module.css";
import { NavLink } from "react-router-dom";

export const Sidebar = () => {




  return (
    <div className={style.main_sidebar}>
      <ul className={style.menu_sidebar}>
        <NavLink to={"/dashboard"}>
          <li>Dashboard</li>
        </NavLink>
        <NavLink to={"/staking-pool"}>
          <li>Staking Pools</li>
        </NavLink>
        <NavLink to={"/droplottery"}>
          <li>Drop Lottery</li>
        </NavLink>
        <li>
          <a
            href="https://goldstandardgrp.com"
            target="_new"
            style={{ color: "white", textTransform: "none" }}>
            NFT Launchpad
          </a>{" "}
        </li>
        <li>
          <a
            href="https://bogle.app"
            target="_new"
            style={{ color: "white", textTransform: "none" }}
          >
            Bogle App
          </a>
        </li>
        <li>
          <a
            href="https://gold-standard.gitbook.io/the8020contract/"
            target="_new"
            style={{ color: "white", textTransform: "none" }}
          >
            Whitepaper
          </a>{" "}
        </li>
        <li>
          <a
            href=" https://discord.com/invite/p4gMyP99pZ"
            target="_new"
            style={{ color: "white", textTransform: "none" }}
          >
            Discord
          </a>
        </li>
        <li>
          <a
            href="https://t.me/The8020contractchat"
            target="_new"
            style={{ color: "white", textTransform: "none" }}
          >
            Telegram
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/the8020contract"
            target="_new"
            style={{ color: "white", textTransform: "none" }}
          >
            Twitter
          </a>
        </li>
        {/* <li>
        <div className={style.copylink} onClick={copyToClipboard}>
              <button
                type="button"
                // class="btn btn-primary"
                className={style.btn_headerCopy}
                data-toggle="modal"
                data-target="#exampleModalCenters"
              >
                Copy Ref.Link
              </button>
            </div>
        </li> */}
        {/* <li>Team</li> */}
      </ul>
    </div>
  );
};

import React from "react";
import style from "./index.module.css";
import { ImCross } from "react-icons/im";
import logo from "../../assets/Icons/Group900.svg";
import { NavLink } from "react-router-dom";

export const MobSidebar = ({
  ToggleSidebar,
  isOpen,
  currency,
  eth,
  withdrawn,
  totalEThCommunity,
  totalETh,
}) => {
  return (
    <div>
      <div
        className={`${style.sidebar} ${
          isOpen == true ? `${style.active}` : ""
        }`}
      >
        <div className={style.sd_header}>
          {/* <h4 className="mb-0">Sidebar Header</h4> */}
          <img src={logo} alt="logo" style={{ width: "120px" }} />
          <div className={style.btn_primary} onClick={ToggleSidebar}>
            {/* <i className="fa fa-times"></i> */}
            <ImCross />
          </div>
        </div>
        <div className={style.sd_body}>
          <ul>
            <li>
              <NavLink to="/dashboard" className={style.sd_link}>
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to={"/staking-pool"} className={style.sd_link}>
                Staking Pool
              </NavLink>
            </li>
            <li>
              <NavLink to={"/droplottery"} className={style.sd_link}>
                Drop Lottery
              </NavLink>
            </li>
            <li>
              <a
                href="https://goldstandardgrp.com"
                target="_new"
                className={style.sd_link}
              >
                NFT Launchpad
              </a>
            </li>
            <li>
          <a
            href="https://bogle.app"
            target="_new"
            className={style.sd_link}
          >
            Bogle App
          </a>
        </li>
            <li>
              <a
                href="https://gold-standard.gitbook.io/the8020contract/"
                target="_new"
                className={style.sd_link}
              >
                Whitepaper
              </a>
            </li>
            <li>
              <a
                className={style.sd_link}
                href=" https://discord.com/invite/p4gMyP99pZ"
                target="_blank"
              >
                Discord
              </a>
            </li>
            <li>
              <a
                className={style.sd_link}
                href="https://t.me/The8020contractchat"
                target="_blank"
              >
                Telegram
              </a>
              </li>
            <li>
              <a
                className={style.sd_link}
                href="https://twitter.com/the8020contract"
                target="_blank"
              >
                Twitter
              </a>
            </li>
            {/* <li>
              <a className={style.sd_link}>Team</a>
            </li> */}
          </ul>

          <div className={style.boxes_headerSidebar}>
            <div className={style.nested_box}>
              <p>
                Total Deposited - {eth !== "loading" ? eth : 0} {currency}
              </p>
              <p>
                Total Withdrawn - {withdrawn !== "loading" ? withdrawn : 0}{" "}
                {currency}
              </p>
            </div>
            <div className={style.nested_box}>
              <p>
                Access Pool - {totalETh && totalETh} {currency && currency}
              </p>
              <p>
                Community Pool - {totalEThCommunity && totalEThCommunity}{" "}
                {currency && currency}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${style.sidebar_overlay} ${
          isOpen == true ? `${style.active}` : ""
        }`}
        onClick={ToggleSidebar}
      ></div>
    </div>
  );
};

import React from 'react'
import {MusicHeader} from '../MusicDropDown/MusicHeader'
import {SideBarDropDown} from '../MusicDropDown/SidebarDropDown'
export const ArtdropDown = () => {
    return (
        <div>
       <MusicHeader/>
       <SideBarDropDown/>
        </div>

    )
}

import React from 'react'
import style from "./BuyGS50.module.css";

export const BuyGS50 = () => {
    return (
        <div className={style.BuyGS50_Section}>
            <div className={style.nested_BuyGS50}>
                <div className={style.heading_section}>
                    <h1>Exchange collateral for GS Tokens</h1>
                </div>
                <div className={style.content_section}>
                    <div className={style.content_sectionOne}>
                        <div className={style.heading_content_section}>
                            <h6>Connect Your Wallet</h6>
                        </div>
                        {/* data */}
                        <div className={style.data_section}>
                            <span>1</span>
                            <div className={style.data}>
                                Use your Web3 enabled browser to connect to The 80/20 website.  We operate on Binance Smart Chain, Polygon, and Ethereum Networks.  This website will only work with secure crypto wallets.
                            </div>
                        </div>
                    </div>

                    {/* section two */}
                    <div className={style.content_sectionOne}>
                        <div className={style.heading_content_section}>
                            <h6>Exchange your tokens</h6>
                        </div>
                        {/* data */}
                        <div className={style.data_section}>
                            <span>2</span>
                            <div className={style.data}>
                                Exchange BNB, MATIC, or ETH for GS tokens.  Your wallet will immediately qualify to start receiving rewards based on your reward ratio.  Users must claim rewards, commission, and bunuses from the user dashboard.
                            </div>
                        </div>
                    </div>
                    {/* section 3 */}
                    <div className={style.content_sectionOne}>
                        <div className={style.heading_content_section}>
                            <h6>Use, Trade, Gift, or Sell</h6>
                        </div>
                        {/* data */}
                        <div className={style.data_section}>
                            <span>3</span>
                            <div className={style.data}>
                                Your GS tokens can be used to buy NFTs or gifted to friends. When gifting GS tokens, your friends can also collect rewards. The tokens can also be traded or sold back to The 80/20 contract in exchange for collateral.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

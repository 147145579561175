import React, { useState, useEffect } from "react"
import styles from "../index.module.css"
import access from "../../../assets/images/minToken2.gif"
import { useContractAccessKey,useNftRewardPoolContract } from "../../../../Hooks/nftPool"
import { useContract } from "../../../../Hooks"
import { useWeb3React } from "@web3-react/core"
import { getAccessKeyContractAddress } from "../../../../utils"
import { formatEther } from "@ethersproject/units"
import { ImCross } from "react-icons/im"
import { Btn } from "../../../components"

export default function AccessNFT() {
  const tokenContract = useContractAccessKey()
  const GS50Contract = useContract()
  const { account, chainId } = useWeb3React()
  const accessKeyAddress = getAccessKeyContractAddress(chainId)

  const [price, setPrice] = useState("0")
  const [priceInGS50, setPriceInGS50] = useState("0")
  const [currency, setCurrency] = useState("loading")
  const [tokenIds, setTokenIds] = useState([]);
  const [stakeText, setStakedText] = useState('');
  const  [stakeNumber, setStakeNumber]=useState();

  // const { account } = useWeb3React();
  // const tokenContract = useContractAccessKey();
  const nftContract = useNftRewardPoolContract();

  let count = 0;

  useEffect(async () => {
    if (tokenContract) {
      let staked = (await nftContract.isStaked(account)).toString();
      let stakedId = (await nftContract.stakedNft(account)).toString();
      staked && setStakedText(`${staked}`);
      staked && setStakeNumber(`${stakedId}`)
      async function getId() {
        let balance = (await tokenContract.balanceOf(account)).toString();
        if (count < balance) {
          let tokenId = (
            await tokenContract.tokenOfOwnerByIndex(account, count)
          ).toString();
          setTokenIds((i) => [...i, tokenId]);
          count++;
          getId();
        }
      }
      getId();
    }
  }, [tokenContract]);




  useEffect(async () => {
    if (tokenContract) {
      setPrice((await tokenContract.basePrice()).toString())
      setPriceInGS50((await tokenContract.getPriceInGS50()).toString())

      if (chainId === 80001 || chainId === 137) {
        setCurrency("MATIC")
      } else if (chainId === 56 || chainId === 97) {
        setCurrency("BNB")
      } else if (chainId === 43113 || chainId === 43114) {
        setCurrency("AVAX")
      } else {
        setCurrency("ETH")
      }
    }
  }, [tokenContract])

  async function buy() {
    let overrides = {
      value: price,
    }
    console.log(overrides)
    let tx = await tokenContract.buyNft(overrides)
    if (await tx.wait()) {
      window.location.reload()
    }
  }

  async function buyWithGS50() {
    console.log(`address ${accessKeyAddress}`)
    let allowance = (await GS50Contract.allowance(account, accessKeyAddress)).toString()

    if (Number(allowance) >= Number(priceInGS50)) {
      try {
        let tx = await tokenContract.buyNftWithGS50(priceInGS50)
        if (await tx.wait()) {
          window.location.reload()
        }
      } catch (e) {
        alert(e.message)
      }
    } else {
      let tx = await GS50Contract.approve(
        accessKeyAddress,
        "2345678909876543456789098765430000000000000000000000"
      )
      if (await tx.wait()) {
        try {
          let tx = await tokenContract.buyNftWithGS50(priceInGS50)
          if (await tx.wait()) {
            window.location.reload()
          }
        } catch (e) {
          alert(e.message)
        }
      }
    }
  }

  return (
    <>
      <h3>Access NFT</h3>

      <div className={styles.side_content}>
        <div style={{ width: "100%" }}>
          <img src={access} alt="gif" width={"175px"} height={"175px"} />
          <p className={styles.stakes_para}>
              NFT STAKED? {''}
              <span className={` ${styles.amount_nft}`}>{stakeText}</span>
            </p>
            <p className={styles.stakes_para}>
            Token Id {' '}
            <span className={` ${styles.amount_nft}`}>#{stakeNumber}</span>
            </p>
            <p style={{fontSize: '16px'}} className={` ${styles.stakes_para}`}>
              Tokens In Wallet {' '} (
              {tokenIds.map((i, index) => {
                return (
                  <span className={` ${styles.amount_nft}`}>
                    {' '}
                    #{i}
                    {index === tokenIds.length - 1 ? '' : ','}{' '}
                  </span>
                );
              })}
              )
            </p>
        </div>
        <div className={styles.description}>
          <p>
            500 Max Supply. After purchasing the asset you must then STAKE the NFT to be eligible for rewards from our ecosystem.  Claim anytime.
          </p>
          <a
            className={`btn ${styles.change_currency}`}
            data-bs-toggle="modal"
            href="#ACCESSNFTModalToggle"
            role="button"
          >
            Price: {formatEther(price)}
          </a>
        </div>
      </div>

      <div
        className={`modal fade ${styles.modal_section}`}
        id="ACCESSNFTModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        <div className={`modal-dialog modal-dialog-centered`}>
          <div className={`modal-content ${styles.nested_modal}`}>
            <div className={styles.top_section}>
              <button
                type="button"
                className={`text-danger ${styles.modal_btn}`}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <ImCross />
              </button>
            </div>
            <div className="modal-body text-white " style={{ color: "black" }}>
              <h5 style={{textAlign: 'center'}} class="modal-title text-white pb-3" id="exampleModalLabel">
                ACCESS NFT
              </h5>
              <h5 style={{textAlign: 'center'}} class="modal-title text-white pb-3" id="exampleModalLabel">
                Pay with GS50 Tokens or {currency}
              </h5>
              <div className={` py-2 ${styles.modal_child}`}>
                <p className="h5 text-center">{currency} Price</p>
                <Btn value={`Price:${formatEther(price)} `} onClick={buy} />
              </div>
              <div className={` py-2 ${styles.modal_child}`}>
                <p className="h5 text-center">GS50 Price</p>
                <Btn
                  value={`Price:${Number(formatEther(priceInGS50)).toFixed(2)} `}
                  onClick={buyWithGS50}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, { useState } from "react";
import { Timeline } from "react-responsive-timeline";
import "./timeline.css";

export const TimelineSection = () => {
  const [position, setPosition] = useState({
    pivot: "horizontal",
    direction: "center",
  });
  const [tooltip, setTooltip] = useState(false);
  const [textLimit, setTextLimit] = useState("none");
  var w = window.innerWidth;
  console.log(w);
  window.addEventListener("resize", function () {
    if (window.innerWidth <= "768") {
      setPosition({
        pivot: "vertical",
        direction: "left",
      });
    } else {
      setPosition({
        pivot: "horizontal",
        direction: "center",
      });
    }
  });
  const _handeChange = (pivot, direction) => () => {
    setPosition({
      pivot,
      direction,
    });
  };

  //   const _handeTooltip = () => {
  //     setTooltip(!tooltip);
  //   };

  //   const _handeTextLimit = (value) => () => {
  //     setTextLimit(value);
  //   };

  return (
    <div>
      <div className="timeline_section">
        {/* <button type="button" onClick={_handeChange("vertical", "left")}>
          Vertical left
        </button>
        <button type="button" onClick={_handeChange("vertical", "center")}>
          Vertical center
        </button>
        <button type="button" onClick={_handeChange("vertical", "right")}>
          Vertical right
        </button>
        <button type="button" onClick={_handeChange("horizontal", "left")}>
          Horizontal left
        </button>
        <button type="button" onClick={_handeChange("horizontal", "center")}>
          Horizontal center
        </button>
        <button type="button" onClick={_handeChange("horizontal", "right")}>
          Horizontal right
        </button> */}
        <div className={"heading_section"}>
          <h1>RoadMap GS50</h1>
        </div>
        <div className="timeLineData" style={{ width: "90%", margin: "0 auto" }}>
          <Timeline
            pivot={position.pivot}
            direction={position.direction}
            tooltip={tooltip}
            textLimit={textLimit}
            timelines={[
              {
                title: "Launched GS20 on Ethereum",
                sub: "October 2020",
              },
              {
                title:
                  "Launched GS50 on Binance Smart Chain",
                sub: "February, 2021",
              },
              {
                title: "HTML site upgraded to React",
                sub: "July 2021",
              },
              {
                title: "NFT Staking pools opened",
                sub: "August 2021",
              },
              {
                title: "Drop Lottery Opened",
                sub: "September 2021",
              },
              {
                title: "Launched GS50 on Polygon",
                sub: "October 2021",
              },
              {
                title: "Opened V1 of NFT Launchpad",
                sub: "March 2022",
              },
              {
                title: "V3 website upgrade",
                sub: "November 2022",
              },
              {
                title: "NFT Launchpad V2",
                sub: "March 2023",
              },
              
            ]}
          />
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react"
import styles from "./index.module.css"
import tip from "../../../assets/images/tip.png"
import { useContract } from "../../../../Hooks/lottery"
import { formatValueOfPick4 } from "../../../../utils"

export const LotteryFrom2 = () => {
  const contract = useContract()

  const [pick4WinnerData, setPick4WinnerData] = useState([])

  useEffect(async () => {
    if (contract) {
      const pick4WinnerEvents = await contract.queryFilter(
        contract.filters.Pick4Winner()
      )

      pick4WinnerEvents.map((e) => {
        setPick4WinnerData((prevArray) => [
          ...prevArray,
          {
            time: String(e.args.time),
            wallet: String(e.args.winner),
            txHash: String(e.transactionHash),
            number: String(e.args.number),
          },
        ])
        return true
      })
    }
  }, [contract])

  function calDate(time) {
    const dateobj = new Date(time * 1000)
    const day = dateobj.getDate()
    const month = Number(dateobj.getMonth()) + 1
    const year = dateobj.getFullYear()
    return `${day}/${month}/${year}`
  }

  return (
    <div className={`mt-3 ${styles.lotteryfrom1}`}>
      <h1
        class={`display-6 text-center mt-3 mb-3 ${styles.lotteryfrom1_heading}`}
      >
        PICK 4 DROP HISTORY
        <div class={`${styles.lotteryfrom1_img}`}>
          <img
            src={tip}
            alt=""
            data-toggle="tooltip"
            title="History of all drops in the Pick 4."
          />
        </div>
      </h1>

      <div class={`table-responsive ${styles.Lotttery_Form1_Table1} `}>
        <table class="table  table-borderless">
          <thead>
            <tr className="text-white">
              <th scope="col">No:</th>
              <th scope="col">Date</th>
              <th scope="col">Wallet</th>
              <th scope="col">Txn Hash</th>
              <th scope="col">Number</th>
              <th scope="col">Pick</th>
            </tr>
          </thead>
          <tbody className="text-white">
            {pick4WinnerData.map((e, i) => {
              return (
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td>{calDate(e.time)}</td>
                  <td>{e.wallet}</td>
                  <td>{`${e.txHash.substring(0, 6)}...${e.txHash.substring(
                    e.txHash.length - 4
                  )}`}</td>
                  <td>{formatValueOfPick4(e.number)}</td>
                  <td>4</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

import React from "react";
import style from "./index.module.css";
import footerlogo from "../../assets/images/footerlogo.png";
// import { Btn } from "../../components";
import gsglogo from "../../assets/images/gsglogo.png";

export const Footer = () => {
  return (
    <div className={style.main_footer}>
      <div className={style.footer_section}>
        <div className={style.desc}>
          <p>
            Before purchasing cryptocurrency and participating in the 80/20
            Smart Contract, you must ensure that the nature, complexity and
            risks inherent in the investment arena are suitable for your
            objectives in light of your circumstances and financial position.
            You should not purchase cryptocurrency unless you understand the
            extent of your exposure to potential loss. The 80/20 Smart Contract
            and its affiliates, agents, and sponsors will not be liable for any
            actions taken as a result of your use of this website or
            participation in the 80/20 Smart Contract. For more information
            please read the disclaimer.
          </p>
          <div className={style.link_div}>
            {/* <Btn value={"DISClAIMER"} /> */}
            <a
              href="https://gold-standard.gitbook.io/the8020contract/disclaimer"
              target="_blank"
            >
              DISCLAIMER
            </a>
          </div>
        </div>
        <div className={style.link_section}>
          <div className={style.logo}>
            {/* <h1>helo</h1> */}
            <img src={footerlogo} alt="footerlogo" width={"160px"} />
          </div>
          <div className={style.link_one}>
            <p>Important Links</p>
            <ul>
              <li>
                <a href="https://goldstandardgrp.com" target="_blank">GSG Corporate Site</a>{" "}
              </li>
              <li>
                <a href="https://gold-standard.gitbook.io/the8020contract/" target="_blank">80/20 Whitepaper</a>
              </li>
              <li>
                <a href="https://gold-standard.gitbook.io/the8020contract/contract-addresses" target="_blank">Contract Addresses</a>{" "}
              </li>
            </ul>
          </div>
          <div className={style.link_two}>
            {/* <h1>helo</h1> */}
            <p>Contact Links</p>
            <ul>
              <li>
                <a href="https://discord.gg/p4gMyP99pZ" target="_blank">Discord</a>
              </li>
              <li>
                <a href="https://t.me/The8020contractchat" target="_blank">Telegram</a>
              </li>
              <li>
                <a href="https://twitter.com/the8020contract/" target="_blank">Twitter</a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCvlc6hb95n_Wl0vyPbK15DQ" target="_blank">YouTube</a>
              </li>
            </ul>
          </div>
          <div className={style.link_three}>
            {/* <h1>helo</h1> */}
            <p>Partner Links</p>
            <ul className={style.ul_list}>
              <li>
                <a href="https://goldstandardgrp.com" target="_new">NFT Launchpad</a>
              </li>

              <li>
                <a href="https://gsg-mastermind.com" target="_new">DCG Mastermind</a>
              </li>

              <li>
                <a href="https://nftkng.com" target="_new">GSG Memberships</a>
              </li>
            </ul>
          </div>
          <div className="img">
            {/* <h1>helo</h1> */}
            <img
              src={gsglogo}
              alt="footerlogo"
              width={"160px"}
              //   style={{ marginTop: "-10px" }}
            />
            {/* <p>hello</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import styles from './ThreeBoxes.module.css';
import FirstImage from '../../Icons/smartcontract.png';
import NFTImage from '../../Icons/NFT.png';
import EarnImage from '../../Icons/GS.png';
// import Aos from 'aos'
// import { useEffect } from 'react';



export const ThreeBoxes = () => {
  


    return (

        <div className={`container ${styles.ThreeBox_mainDiv}`}>
        <div class={` row  justify-content-center text-white  mb-5  ${styles.mainThreeBox_cont} `}>
                <div class={`col  pt-md-4 ${styles.firstBox}`}>
                    <span className={`px-3  fs-4 fw-bolder`}><img src={FirstImage} alt="" /><span className={`px-2`}>Smart Contract </span></span>
                    <p className={`px-3 pt-2 fw-lighter`}>Powered by carbon free blockchains to secure liquidity and manage token distribution.</p>
                </div>
                <div class={`col  pt-md-4 ${styles.SecondBox}`}>
                    <span className={`px-3  fs-4 fw-bolder`}><img src={NFTImage} alt="" /><span className={`px-2`}>NFT Launchpad</span></span>
                    <p className={`px-3 pt-2 fw-lighter`}>NFT creation service to help creators launch digital art, music, and social initiatives.</p>
                </div>
                <div className={`col  pt-md-4 ${styles.ThreeBoxes}`}>
                    <span className={`px-3  fs-4 fw-bolder`}><img src={EarnImage} alt="" /><span className={`px-2`}>Passive Rewards</span></span>
                    <p className={`px-3 pt-2 fw-lighter`}>Hold GS Tokens and use the staking pools for maximum passive rewards.</p>
                </div>
            </div>
         </div>
    )
}


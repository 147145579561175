import React, { useState, useEffect } from "react";
import style from "./index.module.css";
import { Sidebar, Header, Footer } from "../../layout";
import bnb from "../../assets/images/bnb.png";
import ethLogo from "../../assets/images/eth.png";
import base from "../../assets/images/base.png";
import avax from "../../assets/images/avax.png";
import matic from "../../assets/images/polygon.png";
import importantImage2 from '../../assets/images/Kosaha-NFT.png';
import importantImage from '../../assets/images/important.png';
import gs50 from "../../assets/images/GS50.svg";
import { FaAngleDown } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";

import advertistment from "../../assets/images/AdBanner.png";
import { Accordion } from "../../components";
import tip from "../../assets/images/tip.png";

import { tokenAbi } from "./IERC20.json";
import { Contract } from "@ethersproject/contracts";

import { Btn } from "../../components";
import { useWeb3React } from "@web3-react/core";
import {
  formatNumber,
  formatNumberInDecimals,
  formatValue,
  fromWei,
  getErrorMessage,
  toWei,
  getNftRewardPoolAddress,
  getContractaddress,
  getProviderOrSigner,
} from "../../../utils";
import {
  useContract,
  useEagerConnect,
  useGS20Contract,
  useInactiveListener,
} from "../../../Hooks";
import {
  useContractAccessKey,
  useNftRewardPoolContract,
  // hooks NFT Pool
  useContractCommunityToken,
  useNftCommunityRewardPoolContract2,
} from "../../../Hooks/nftPool";

import { injected } from "../../../connectors";
import { formatEther, formatUnits, parseEther } from "@ethersproject/units";

export const DashboardUpdgrade = () => {
  const { account, library, chainId, activate, error } = useWeb3React();
  const [showOverlay, setShowOverlay] = useState(true);
  const [showSecondOverlay, setShowSecondOverlay] = useState(false);

  // access Pool for NFT
  const contract = useContract();
  const GS20Contract = useGS20Contract();
  const tokenContract = useContractAccessKey();
  const nftContract = useNftRewardPoolContract();
  const nftAddress = getNftRewardPoolAddress(chainId);
  //  community Pool
  const tokenContractCommunity = useContractCommunityToken();
  const nftContractCommunity = useNftCommunityRewardPoolContract2();

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager);

  // const [userBalance, setUserBalance] = useState("loading");
  const [userDivs, setUserDivs] = useState(0);
  const [userRef, setUserRef] = useState(0);
  const [userLoyalty, setUserLoyalty] = useState(0);
  const [tokenBalance, setTokenBalance] = useState("loading");
  const [tvl, setTvl] = useState(0);
  const [totalSupplyNumber, setTotalSupplyNumber] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [totalWallets, setTotalWallets] = useState(0);
  const [eth, setEth] = useState("loading");
  const [withdrawn, setWithdrawn] = useState("loading");
  const [currency, setCurrency] = useState("");
  const [totalValue, setTotalValue] = useState("");
  const [amountBuy, setAmountBuy] = useState("");
  const [amountSell, setAmountSell] = useState("");

  const [ethPrice, setEthPrice] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [refAddress, setRefAddress] = useState("loading");
  const [chainName, setChainName] = useState("Loading");
  // const [totalETh, setTotalEth] = useState(0);
  // const [totalEThCommunity, setTotalEthCommunity] = useState(0);
  // const [totalReward, setTotalReward] = useState('Loading');
  // const [totalStaked, setTotalStaked] = useState('Loading');
  const [balance, setBalance] = useState("");


  const networks = {
    AVAX: {
      chainId: '0xA86A', // 43114 in hexadecimal
      chainName: 'Avalanche Network',
      nativeCurrency: {
        name: 'Avalanche',
        symbol: 'AVAX', // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'], // Add the correct RPC URL for AVAX
      blockExplorerUrls: ['https://snowtrace.io/'], // Add the correct block explorer URL for AVAX
    },
    BASE: {
      chainId: '0x2105', // 8453 in hexadecimal
      chainName: 'BASE Network',
      nativeCurrency: {
        name: 'Ethereum',
        symbol: 'ETH', // 2-6 characters long
        decimals: 18,
      },
      rpcUrls: ['https://base.drpc.org'], // Add the correct RPC URL for BASE
      blockExplorerUrls: ['https://basescan.org/'], // Add the correct block explorer URL for BASE (if available)
    }
  };
  
  async function addNetwork(networkKey) {
    const networkData = networks[networkKey];
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [networkData],
      });
    } catch (addError) {
      console.error(addError);
    }
  }
  

  // Fetch total supply
  async function getTotalSupply() {
    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        "x-api-key": "2Y5lzswki9RgDSb9Kz0rXsCKI3s",
      },
    };
    console.log(getContractaddress(chainId), chainId);
    fetch(
      `https://api.chainbase.online/v1/token/holders?chain_id=${chainId}&contract_address=${getContractaddress(
        chainId
      )}&page=1&limit=20`,
      options
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.data) {
          setTotalWallets(response.count);
        }
      })
      .catch((err) => console.error(err));
  }

  // added the clipbaord referlink
  useEffect(() => {
    let address;
    if (refAddress !== "loading") {
      var url = document.URL;
      if (url.indexOf("?") !== -1) {
        if (refAddress === "0x0000000000000000000000000000000000000000") {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          address = urlParams.get("ref");
          setRefAddress(address);
        }
      } else {
        if (refAddress === "0x0000000000000000000000000000000000000000") {
          address = "0xfce153da366d5d65aa04d0a53f1110df9e074834";
          setRefAddress(address);
        }
      }
    }
  }, [library, contract, refAddress]);

  useEffect(() => {
    if (library) {
      async function runGetBalance() {
        let getBalance = await library.getBalance(account);
        getBalance = formatEther(getBalance.toString());
        getBalance = Number(getBalance).toFixed(2);
        setBalance(getBalance);
      }

      runGetBalance();
      getTotalSupply();
    }
  }, [library]);

  async function withdraw() {
    if (contract) {
      try {
        const tx = await contract.withdraw();
        if (await tx.wait()) window.location.reload();
      } catch (e) {
        if (e.code === 4001) return;
        let error = JSON.parse(JSON.stringify(e));
        console.log(error.error.message);
      }
    }
  }

  async function cycle() {
    if (contract) {
      try {
        const tx = await contract.reinvest();
        if (await tx.wait()) window.location.reload();
      } catch (e) {
        if (e.code === 4001) return;
        let error = JSON.parse(JSON.stringify(e));
        console.log(error.error.message);
      }
    }
  }

  const buyToken = async () => {
    if (contract) {
      const amount = toWei(document.getElementById("swapEth").value);
      if (amount === 0) alert("ERROR: no amount");
      let overrides = {
        value: amount,
      };

      if (
        refAddress !== "loading" ||
        refAddress !== "0x0000000000000000000000000000000000000000"
      ) {
        const tx = await contract.buy(refAddress, overrides);
        if (await tx.wait()) window.location.reload();
      } else {
        alert("Referral Address is not loaded yet, TRy AGAIN");
      }
    }
  };

  const sellToken = async () => {
    if (contract) {
      const amount = toWei(document.getElementById("swapGs50").value);
      if (amount === 0) alert("ERROR: no amount");
      const tx = await contract.sell(amount);
      tx.wait();
      if (await tx.wait()) window.location.reload();
    }
  };

  const calculateToken = async (eth) => {
    if (contract) {
      const token = await contract.calculateTokensReceived(eth);
      setAmountBuy(
        `you will receive ${formatNumber(fromWei(token))} GS50 Token`
      );
    }
  };

  const calculateEth = async (token) => {
    if (contract) {
      const eth = await contract.calculateEthereumReceived(token);
      setAmountSell(
        `You will get  ${Number(fromWei(eth)).toFixed(6)} ${currency}`
      );
    }
  };

  const swapGs20 = async () => {
    if (TokenAmountSwap < 1) {
      alert("HAVE SOME BALANCE");
      return;
    }
    if (contract) {
      const allowance = await GS20Contract.allowance(
        account,
        getContractaddress(chainId)
      );
      console.log("ALLOWANCE", allowance);
      if (toWei(TokenAmountSwap) <= allowance) {
        const tx = await contract.swapGS20toGS50(toWei(TokenAmountSwap));
        console.log(tx);
      } else {
        const tx = await GS20Contract.approve(
          getContractaddress(chainId),
          toWei(TokenAmountSwap)
        );
        if (await tx.wait()) {
          swapGs20();
        }
      }
    }
  };

  useEffect(async () => {
    console.log("library", library);
    if (contract) {
      // let tokens = await contract.myTokens();
      // setUserBalance(formatValue(tokens));
      console.log("setting numners");
      let divs = await contract.dividendsOf(account);
      setUserDivs(formatNumberInDecimals(divs));

      let refCom = await contract.referralBalance_(account);
      setUserRef(formatNumberInDecimals(refCom));

      let loyalty = await contract.loyaltyOf();
      setUserLoyalty(formatNumberInDecimals(loyalty));

      let tokenBal = await contract.balanceOf(account);
      setTokenBalance(formatNumberInDecimals(tokenBal));
      //  console.log(tokenBal);
      let total_ETH = await contract.totalEthereumBalance();
      let total_ETH_in_ether = Number(fromWei(total_ETH)).toFixed(4);
      setTvl(total_ETH_in_ether);

      let total_supply = await contract.totalSupply();
      setTotalSupply(formatValue(total_supply));
      setTotalSupplyNumber(formatEther(total_supply.toString()));
      let totalSupplyValue = await contract.calculateEthereumReceived(
        total_supply
      );
      setTotalValue(formatValue(totalSupplyValue));

      let total_dep = await contract.totalDeposited();
      setEth(formatValue(total_dep));

      let total_withdraw = await contract.totalWithdraw();
      setWithdrawn(formatValue(total_withdraw));

      if (chainId === 80001 || chainId === 137) {
        // setLogoInCard(matic);
        setCurrency("MATIC");
      } else if (chainId === 56 || chainId === 97) {
        // setLogoInCard(binance);
        setCurrency("BNB");
      } else if (chainId === 8453) {
        // setLogoInCard(ethereum);
        setCurrency("BASE ETH");
      } else if (chainId === 43114 || chainId == 43113) {
        // setLogoInCard(avax);
        setCurrency("AVAX");
      } else {
        // setLogoInCard(ethereum);
        setCurrency("ETH");
      }
    }
  }, [contract, library]);

  useEffect(async () => {
    if (contract) {
      let buyPrice = await contract.buyPrice();
      buyPrice = fromWei(+buyPrice.toString());
      setTokenPrice(buyPrice * ethPrice);
      console.log(`buy price ${tokenPrice}`);
      const refId = await contract.getReferrer();
      setRefAddress(refId);
      // switchChain();
      // console.log('library', library.provider);
    }
    if (chainId === 1) {
      setChainName("Ethereum");
    } else if (chainId === 5) {
      setChainName("Goreli Testnet");
    } else if (chainId === 56) {
      setChainName("Binance Chain");
    } else if (chainId === 137) {
      setChainName("Polygon");
    } else if (chainId === 80001) {
      setChainName("Polygon Testnet");
    } else if (chainId === 97) {
      setChainName("Binance Testnet");
    } else if (chainId === 8453) {
      setChainName("BASE Network");
    } else if (chainId === 43113) {
      setChainName("AVAX TEST Network");
    } else if (chainId === 43114) {
      setChainName("AVAX");
    } else if (chainId === undefined) {
      setChainName("Please Connect Wallet");
    } else {
      setChainName("Wrong chain.  Check network settings.");
    }
  }, [contract, ethPrice, chainId]);

  useEffect(() => {
    const fetchPrice = async () => {
      const coinIds = {
        '56': 'binancecoin', // BSC Mainnet
        '97': 'binancecoin', // BSC Testnet
        '80001': 'matic-network', // Polygon Testnet (Mumbai)
        '137': 'matic-network', // Polygon Mainnet
        '43114': 'avalanche-2', // Avalanche Mainnet
        '43113': 'avalanche-2', // Avalanche Testnet (Fuji)
        '8453': 'ethereum', // Base Ethereum (Fuji)
      };
      const defaultCoinId = 'ethereum';
      const coinId = coinIds[chainId] || defaultCoinId;
  
      // Attempt to fetch from CoinGecko
      try {
        const response = await fetch(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${coinId}`);
        if (!response.ok) throw new Error('CoinGecko fetch failed');
        const data = await response.json();
        setEthPrice(data[0].current_price);
        return;
      } catch (error) {
        console.error('CoinGecko fetch failed, trying Binance:', error);
      }
  
      // Fallback to Binance
      try {
        const binanceSymbol = coinId === 'binancecoin' ? 'BNB' : coinId === 'matic-network' ? 'MATIC' : 'ETH';
        const response = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${binanceSymbol}USDT`);
        if (!response.ok) throw new Error('Binance fetch failed');
        const data = await response.json();
        setEthPrice(parseFloat(data.price));
        return;
      } catch (error) {
        console.error('Binance fetch failed, retrying CoinGecko:', error);
        // Recursive call to retry
        fetchPrice();
      }
    };
  
    fetchPrice();
  }, [library, contract, chainId]);
  

  const [TokenAmount, setTokenAmount] = useState(0);
  const [TokenAmountSwap, setTokenAmountSwap] = useState(0);
  const [TokenAddress, setTokenAddress] = useState();

  async function TRANSFERTOKENS() {
    const tokenContract = new Contract(
      getContractaddress(chainId),
      tokenAbi,
      getProviderOrSigner(library, account)
    );

    const tokenForTransaction = parseEther(TokenAmount).toString();
    try {
      const tx = await tokenContract.transfer(
        TokenAddress,
        tokenForTransaction
      );
      await tx.wait();

      setTokenAmount(0);
      setTokenAddress();
    } catch (error) {
      console.error(error);
    }
  }
  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.style.textTransform = "lowercase";
    el.value = `http://the8020.org/dashboard?ref=${account}`;
    // let valueone=el.value.toLocaleLowerCase();

    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

return (
  <>
    {showOverlay && (
  <div className={style.overlay}>
    <div className={style.overlayContent}>
      <img src={importantImage} alt="Welcome Image" className={style.overlayImage} />
      <h1 className={style.title}>Important Update for GS50 Holders</h1>
      <h2 className={style.subtitle}>Please read before closing</h2>
      <p className={style.paragraph}>
      We are excited to announce that significant changes have come to our ecosystem! These changes necessitate the implementation of upgraded GS50 contracts on the AVAX and BASE networks.  With this upgrade we will shift much of our product launches to AVAX as our primary network and BASE as our secondary network for GS50.  You can add the networks to your wallets here: 
      <button onClick={() => addNetwork('AVAX')} style={{ margin: '5px' }}>ADD AVAX</button>
      <button onClick={() => addNetwork('BASE')} style={{ margin: '5px' }}>ADD BASE</button>

      </p>
      <p className={style.paragraph}>The new contracts have introduced enhanced functionalities for better security and integration with our NFTs and Payment Engine. This move is essential to elevate our ecosystem, ensuring we continue to provide the best services to our community.  Currently, Binace and Polygon have only WITHDRAW and SELL options avaialable while AVAX and BASE have full functions. The enhanced version of GS50 includes a three-level referral system, lower gas fees, and integration with our payment engine.</p>

      <h2 className={style.subtitle}>Action Required for Token Holders</h2>

      <p className={style.paragraph}>To facilitate this transition smoothly, all GS50 and GS20 token holders are required to withdraw any commissions, rewards, and bonuses, then sell their current GS50 holdings on Binance, Ethereum, and Polygon Networks. You will receive back your collateral upcon completion of the sale.  If you are staking in the Community and/or Access pools, you can claim any rewards and unstake your NFTs on Biance and Polygon.  We will provide further instructions on what to do with your NFTs.</p>

      <p className={style.paragraph}>There will be a new project for Binance and Polygon that will introduce a newly branded token equipped with more features and utility.  We will keep you updated on that progress.  If you're unable to sell your GS50 on Binance Network, it's likely that you possess promotional tokens which, regrettably, will not be eligible for unlocking due to this migration process. We appreciate your swift response to this necessary action and join us in <a href="https://t.me/The8020contractchat">Telegram</a> or <a href="https://discord.gg/tu9Bjf7Xcv">Discord</a> to discuss the new upgrades. Also, follow us on <a href="https://twitter.com/the8020contract">X</a> to stay up-to-date on all the happenings inside The 80/20.</p>

      <p className={style.paragraph}>Thank you for your participation in our Social Defi Ecosystem! </p>

      
      <button onClick={() => { setShowOverlay(false); setShowSecondOverlay(true); }} className={style.closeButton}>Close</button>
    </div>
  </div>
      )}

      {showSecondOverlay && (
    <div className={style.overlay}>
      <div className={style.secondOverlayContent}>
      <a href="https://goldstandardgrp.com/projects/kosaha/" target="_new" rel="noopener noreferrer">
      <img src={importantImage2} alt="Welcome Image" className={style.secondOverlayImage} />
    </a>
        <h1 className={style.title}>Interactive NFT Live on AVAX!</h1>
        <p className={style.paragraph}>We are the Social Alliance of Haytian Associations KOSÂHA. We are the administrative advisor, the voice of hundreds of landowners unable to speak out and apply themselves to building a better future for them and their children.</p>
        {/* Your second overlay content */}
        <button onClick={() => setShowSecondOverlay(false)} className={style.closeButton}>Close</button>
      </div>
    </div>
  )}

      <div className={style.DashboardUpgrade}>
      <div className={style.nested_upgradeDashboard}>
        <Header />
        <div className={style.content}>
          <div className={style.sidebar_div}>
            <Sidebar />
          </div>
          <div className={style.main_section}>
            <div className={style.nested_section}>
              <div className={style.network_tab}>
                <div className={style.network_heading}>
                  Connected - {chainName}
                </div>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    activate(injected);
                  }}
                  className={`${style.network_heading} ${style.button_section}`}
                >
                  {" "}
                  {account === undefined
                    ? "Connect Wallet"
                    : account === null
                    ? "None"
                    : `Wallet ${account.substring(0, 6)}...${account.substring(
                        account.length - 4
                      )}`}
                </div>
              </div>
              <div className={style.contract}>
                {/* <div className="heading_contract"> */}
                <h1>Contract Details</h1>
                {/* </div> */}
                <div className={style.contract_status}>
                  {" "}
                  <div className={style.child_status}>
                    <p>TOTAL {currency}</p>
                    <p>LOCKED</p>
                    <p>{tvl}</p>
                    
                    <div className={style.img}>
                      <img
                        src={tip}
                        alt="tip"
                        data-toggle="tooltip"
                        title="This is the total value locked in reserve as liquidity for GS tokens."
                      />
                    </div>
                  </div>
                  <div className={style.child_status}>
                    <p>TOTAL GS50</p>
                    <p>SUPPLY</p>
                    <p>{totalSupply}</p>
                    
                    <div className={style.img}>
                      <img
                        src={tip}
                        alt="tip"
                        title="This is the total circulatig supply of GS tokens on the network."
                      />
                    </div>
                  </div>
                  <div className={style.child_status}>
                    <p>TOTAL</p>
                    <p>HOLDERS</p>
                    <p>{0 || totalWallets}</p>
                    
                    <div className={style.img}>
                      <img
                        src={tip}
                        alt="tip"
                        title="This is the total number of unique wallets holding GS tokens."
                      />
                    </div>
                  </div>
                  <div className={style.child_status}>
                    <p>GS50 Price</p>
                    <p>
                      ${tokenPrice === 0 ? 0 : Number(tokenPrice).toFixed(3)}
                    </p>
                    <p>USD</p>
                    <div className={style.img}>
                      <img
                        src={tip}
                        alt="tip"
                        title="This is the GS50 token price. The pricing logic is managed by The 80/20 Smart Contract and is not influenced by outside market makers."
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.contract}>
                {/* <div className="heading_contract"> */}
                <h1>Your Account Details</h1>
                {/* </div> */}
                <div className={style.contract_status}>
                  <div className={style.child_status}>
                    <p>Rewards</p>
                    <p>{userDivs ? userDivs : 0}</p>
                    <p>{currency}</p>
                    <div className={style.img}>
                      <img
                        src={tip}
                        alt="tip"
                        title="This is the amount of rewards you have earned for holding GS tokens.  Withdraw or compound your rewards at any time."
                      />
                    </div>
                  </div>

                  <div className={style.child_status}>
                    <p>Bonus</p>
                    <p>{userLoyalty ? userLoyalty : 0}</p>
                    <p>{currency}</p>
                    <div className={style.img}>
                      <img
                        src={tip}
                        alt="tip"
                        title="Any wallet holding over 2,500 tokens receive an additional loyalty bonus.  Your bonus will be used if you choose to compound your rewards."
                      />
                    </div>
                  </div>

                  <div className={style.child_status}>
                    <p>Commision</p>
                    <p>{userRef ? userRef : 0}</p>
                    <p>{currency}</p>
                    <div className={style.img}>
                      <img
                        src={tip}
                        alt="tip"
                        title="Here is the total commissions you have been awarded from customers using your referral link."
                      />
                    </div>
                  </div>

                  <div className={style.child_status}>
                    <p>Reward</p>
                    <p>Ratio</p>

                    <p style={{ color: "greenyellow" }}>
                      {tokenBalance !== "loading"
                        ? // Number(Number(Number(tokenBalance) / Number(totalSupplyNumber)).toFixed(2)
                          //   ) * 100
                          Number(
                            Number(
                              (Number(tokenBalance) /
                                Number(totalSupplyNumber)) *
                                100
                            )
                          ).toFixed(2)
                        : 0}
                      %
                    </p>
                    <div className={style.img}>
                      <img
                        src={tip}
                        alt="tip"
                        title="This is your share of the rewards and bonuses.  It is calculated by dividing your token balance by the total circulating supply."
                      />
                    </div>
                    {/* <p>Bnb</p> */}
                  </div>
                </div>
              </div>
              <div className={style.groups_btn}>
                <Btn onClick={withdraw} value={"Withdraw"} />
                {(chainId === 8453 || chainId === 43114) && (
                <Btn onClick={cycle} value={"Compound"} />)}
              </div>
              <div className={style.wallet_balance}>
                <p>Tokens In Your Wallet</p>
                <p>{tokenBalance !== "loading" ? tokenBalance : 0} - GS50</p>
                <p>
                  {balance} - {currency}
                </p>
              </div>
              <div className={style.wallet_balance}>
                <div>
                  {refAddress && (
                    <a className="nav-link" style={{ color: "white" }}>
                      {/* add the class  id-Nub */}
                      {`Your Referral : ${refAddress.substring(
                        0,
                        6
                      )}...${refAddress.substring(refAddress.length - 4)} `}
                    </a>
                  )}
                </div>

                <div>
                  {(chainId === 8453 || chainId === 43114) && (
                    <div className={style.copylink} onClick={copyToClipboard}>
                      <button
                        type="button"
                        className={style.btn_headerCopy}
                        data-toggle="modal"
                        data-target="#exampleModalCenters"
                      >
                        COPY LINK
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={style.side_section}>
            <div className="">
              <div
                className={`${
                  chainId == 1
                    ? `${style.side_heading}`
                    : `${style.side_heading} ${style.heading_section}`
                }`}
              >
                <h3>Exchange Service</h3>
                <p>Buy or Sell GS50 Tokens</p>
              </div>
            </div>
            <div
              className={`${
                chainId == 1
                  ? `${style.token_section}`
                  : `${style.token_section} ${style.set_token}`
              }`}
            >
              <div className={style.netsed_token}>
              {(chainId === 8453 || chainId === 43114) && (
                <div className={style.bnb_section}>
                  <div className={style.bnb_child}>
                    <img
                      src={
                        currency === "BNB"
                          ? bnb
                          : currency === "ETH"
                          ? ethLogo
                          : currency === "AVAX"
                          ? avax
                          : currency === "BASE ETH"
                          ? base
                          : matic
                      }
                      alt="bnb"
                      width={"20px"}
                      height="20px"
                    />
                    <h3>{currency}</h3>
                  </div>
                  <div className={style.puprle_box}>
                    <input
                      id="swapEth"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value > 0) {
                          calculateToken(toWei(value));
                        }
                      }}
                      type="number"
                      placeholder={"Enter the amount of " + currency}
                      style={{ fontSize: "12px" }} // Add this style
                    />
                  </div>
                  <div className={style.bnb_sectionButton}>
                    <p className={style.text_bnb}>{amountBuy}</p>
                    <Btn
                      value={"Buy with " + currency}
                      border="none"
                      bgColor={"#1FAA59"}
                      padding="10px 0px"
                      onClick={buyToken}
                    />
                  </div>
                </div>
                )}
                <div className={style.bnb_section}>
                  {/* <h3>Bnb</h3> */}
                  <div className={style.bnb_child}>
                    <img src={gs50} alt="bnb" width={"20px"} height="20px" />
                    <h3>Gs50</h3>
                  </div>
                  <div className={style.puprle_box}>
                    {/* <span>0.0</span> */}
                    <input
                      id="swapGs50"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value > 0) {
                          calculateEth(toWei(value));
                        }
                      }}
                      type="number"
                      placeholder="Enter the amount of GS50"
                      style={{ fontSize: "12px" }} // Add this style
                    />
                  </div>
                </div>
                <div className={style.bnb_sectionButton}>
                  <p className={style.text_bnb}>{amountSell}</p>
                  <Btn
                    value={"Sell GS50"}
                    border="none"
                    bgColor={"#eb4d4b"}
                    padding="10px 0px"
                    onClick={sellToken}
                  />
                </div>
              </div>
            </div>
            {/* Tranfer Token */}
            <div
              className={`${
                chainId == 1
                  ? `${style.transfer_token_section}`
                  : `${style.transfer_token_section} ${style.update_token_section}`
              }`}
              style={{ marginTop: "4px" }}
            >
              <div className={style.transfer_token}>
                <h3>Transfer Tokens</h3>
                <p>Send GS50 to any Wallet Address</p>
                <div className={style.amount_sectionTransfer}>
                  <p>
                    <span>Amount</span>{" "}
                    <input
                      type="number"
                      value={TokenAmount}
                      onChange={(e) => setTokenAmount(e.target.value)}
                      className={style.amoutnToken}
                    />
                  </p>
                  <p>
                    <span>To:</span>{" "}
                    <input
                      type="text"
                      value={TokenAddress}
                      onChange={(e) => setTokenAddress(e.target.value)}
                    />
                  </p>
                </div>
                <div className={style.btn_sendToken}>
                  <Btn
                    value={"Send"}
                    onClick={TRANSFERTOKENS}
                    border="none"
                    bgColor={"rgb(30,199,212)"}
                    padding="10px 0px"
                  />
                </div>
              </div>
            </div>

            {/* Transfer swaps */}
            {chainId === 1 ? (
              <div
                className={style.transfer_token_section}
                style={{ marginTop: "4px" }}
              >
                <div className={style.transfer_token}>
                  <h3>Swap GS50</h3>
                  <div className={style.amount_sectionTransfer}>
                    <p>
                      <span>Amount</span>{" "}
                      <input
                        type="number"
                        value={TokenAmountSwap}
                        onChange={(e) => setTokenAmountSwap(e.target.value)}
                        className={style.amoutnToken}
                      />
                    </p>
                    <p>
                      <span>you will get {TokenAmountSwap} GS50</span>{" "}
                      {/* <input
                      type="text"
                      value={TokenAddress}
                      onChange={(e) => setTokenAddress(e.target.value)}
                    /> */}
                    </p>
                  </div>
                  <div className={style.btn_sendToken}>
                    <Btn
                      value={"SWAP"}
                      onClick={swapGs20}
                      border="none"
                      bgColor={"rgb(30,199,212)"}
                      padding="10px 0px"
                    />
                  </div>
                  {/* <div className={`${style.amount_sectionTransfer} ${style.update_amount}`}>
                  <span>To:</span>{" "}
                  <input
                    type="text"
                    value={TokenAddress}
                    onChange={(e) => setTokenAddress(e.target.value)}
                    className={style.amoutnToken}
                  />
                </div> */}
                </div>
                {/* <div className={style.btn_sendToken}>
                <Btn
                  value={"Send"}
                  onClick={TRANSFERTOKENS}
                  border="none"
                  bgColor={"rgb(30,199,212)"}
                  padding="10px 0px"
                />
              </div> */}
              </div>
            ) : (
              // </div>
              ""
            )}
          </div>
          <div className={style.dashboard_data}>
            {/* <p style={{ color: "gray" }}>Advertisment space</p> */}
            <img src={advertistment} alt="advertistment" width={"100%"} />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </>
  );
};

import React, { Fragment, useState } from "react";
import {
  SecondComp,
  BackgroundTopImage,
  ThreeBoxes,
  NFTLaunchpad,
  NFTRewardPool,
  G50DropLottery,
  Tokenomics,
  HomeDesc,
  HomeFooter1,
  HomeFooter2,
  HomeConnect,
  BackgroundBottomImage,
  BuyGS50,
  TimelineSection,
} from "../index";
import { Link } from "react-router-dom";
import Group900 from "../../Icons/Group 900.svg";
import howItWorks from "../../Images/GS50-distribution.png";
import "./NavBar.css";
import Swal from "sweetalert2";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export const Navbar = () => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  function popup() {
    Swal.fire({
      imageUrl: "https://the8020.org/Token-Model.png",
      // icon: 'error',
      width: "50%",
      title: "Getting Started",
      html: 'In order to participate you must first connect your digital wallet to this Web3 enabled website.  The Eighty-Twenty application runs on Binance Chain, Polygon, AVAX, Base, and Ethereum networks.  You may need to add these networks to your digital wallet.  Visit <a href="https://chainlist.org" target="_blank">ChainList </a> to add these networks to your wallet. <br> The 80/20 website can be used to mint GS50 tokens by providing liquid collateral to the 80/20 smart contract. 20% of the deposit is used to run The 80/20 Ecosystem while 80% of the collateral is held in reserve. The user is then issued GS50 tokens to the users digital wallet. Wallets that hold at least 1 GS50 tokens can claim REWARDS and BONUSES.  Rewards are calulated for each holder based on the number of GS tokens the wallet (REWARD RATIO). Users can also earn commission when clients use your referral link when exchanging for GS50 tokens. Wallets that hold 2,500 or more GS50 tokens will qualify for the Loyalty Bonus.  <br>If you need to exchange fiat currency to crypto, you can use <a href="https://www.kraken.com/" target="_blank" rel="noopener noreferrer">Kraken </a>. Its a platform where you can convert traditional money into cryptocurrencies. If you do not currently have a cryptocurrency wallet installed please visit <a href="http://www.metamask.io" target="new">MetaMask </a> to download and configure your wallet.  Please remember to protect your private keys and seed words at all times.  They Eighty-Twenty team cannot recover lost tokens.',
    });
  }

  return (
    <Fragment>
      <div className="contianer-home " id="mainPage-Div">
        <div>
          <a className="navbar-brand" href="#/">
            <img src={Group900} alt="" className="mainpage-logo" />
          </a>
        </div>

        <div>
          <ul class="nav justify-content-end" id="home-ul">
            <li class="nav-item">
              <span class="nav-link active" onClick={onOpenModal}>
                Video
              </span>
            </li>
            <li class="nav-item">
              <a
                class="nav-link active"
                onClick={popup}
                aria-current="page"
                href="#/"
              >
                How it works
              </a>
            </li>
          </ul>
        </div>
        <div>
          <form class="d-flex">
            <Link to="/dashboard">
              <input
                type="submit"
                value="Open App"
                className="btn btn-DashBoard"
              />
            </Link>
          </form>
        </div>
      </div>
      <SecondComp />
      <BackgroundTopImage />
      {/* <div > */}
      <ThreeBoxes />
      <NFTLaunchpad />
      <NFTRewardPool />
      <G50DropLottery />
      <Tokenomics />
      <BuyGS50 />
      <TimelineSection />
      {/* </div> */}
      <HomeDesc />
      <HomeConnect />
      <BackgroundBottomImage />
      <HomeFooter1 />
      <HomeFooter2 />
      <Modal open={open} onClose={onCloseModal} center>
      <div className="modal_section">    

        <iframe
          width="100%"
          height="415"
          src="https://www.youtube.com/embed/IpyDAYolvf8?si=OL2JMFt3ZZbigWAo?autoplay=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
              </div>

      </Modal>
    </Fragment>
  );
};

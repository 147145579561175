import React, { useEffect } from 'react';
import styles from './NFTLaunchpad.module.css';
import NFTLaunchpadformusic from '../../Images/NFTLaunchpadformusic.svg';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const NFTLaunchpad = () => {
  useEffect(() => {
    Aos.init({
      duration: 1400,
      easing: 'ease-in-sine',
      once: false,
    });
  });

  return (
    <section className={`${styles.NFT_mainDiv} d-flex align-items-center`}>
      <div className={`container-fluid`}>
        <div className={`row`}>
          <div className={`col-10 mx-auto`}>
            <div className="row">
              <div
                data-aos="fade-right"
                className={`col-lg-6  ${styles.NFTLaunchpad_image}`}
              >
                <img src={NFTLaunchpadformusic} alt="" />
              </div>
              <div
                data-aos="fade-left"
                className={`col-md-6 pt-5 pt-lg-0 text-white d-flex justify-content-center flex-column ${styles.NFTLaunchpad_secondDiv}`}
              >
                <h2 className={`display-6 fw-bolder`}>
                  NFT Launchpad for music, art, and visual creators.
                </h2>
                <p className={`px-4 py-3 ${styles.NFT_para}`}>
                  <span className={`fw-light`}>
                    The NFT launchpad will host drops and sales for emerging
                    creators approved by our platform. Our mission is to help
                    new creators learn how to leverage NFT technology and generate income from the work they produce.  We find these creators through our community, and reward our "Talent Scouts" 5% commission on each NFT sale from the launchpad.  You must also hold GS tokens to refer creaters to our management team.  Our payment model for NFT creators is also an 80/20 split.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

import React, { useEffect, useState } from "react"
import styles from "./index.module.css"
import tip from "../../../assets/images/tip.png"
import { useContract } from "../../../../Hooks/lottery"
import { formatValueOfPick3, formatValueOfPick4 } from "../../../../utils"

export const LotteryFrom3 = () => {
  const contract = useContract()

  const [randomData, setRandomData] = useState([])

  useEffect(async () => {
    if (contract) {
      const randomNumberEvents = await contract.queryFilter(contract.filters.Random())

      randomNumberEvents.map((e) => {
        setRandomData((prevArray) => [
          ...prevArray,
          {
            time: String(e.args.time),
            wallet: String(e.args.Address),
            txHash: String(e.transactionHash),
            number: String(e.args.number),
            pick: String(e.args.pick),
          },
        ])
        return true
      })
    }
  }, [contract])

  function calDate(time) {
    const dateobj = new Date(time * 1000)
    const day = dateobj.getDate()
    const month = Number(dateobj.getMonth()) + 1
    const year = dateobj.getFullYear()
    return `${day}/${month}/${year}`
  }

  return (
    <div className={`mt-3 ${styles.lotteryfrom1}`}>
      <h1 class={`display-6 text-center mt-3 mb-3 ${styles.lotteryfrom1_heading}`}>
        CONTRACT DRAWING HISTORY
        <div class={`${styles.lotteryfrom1_img}`}>
          <img src={tip} alt="" data-toggle="tooltip" title="History of all drops in the Pick 4." />
        </div>
      </h1>

      <div class={`table-responsive ${styles.Lotttery_Form1_Table1} `}>
        <table class="table  table-borderless">
          <thead>
            <tr className="text-white">
              <th scope="col">No:</th>
              <th scope="col">Date</th>
              <th scope="col">Wallet</th>
              <th scope="col">Txn Hash</th>
              <th scope="col">Number</th>
              <th scope="col">Pick</th>
            </tr>
          </thead>
          <tbody className="text-white">
            {randomData.map((e, i) => {
              return (
                <tr>
                  <th scope="row">{i + 1}</th>
                  <td>{calDate(e.time)}</td>
                  <td>{e.wallet}</td>
                  <td>
                    {e.pick === 3 ? formatValueOfPick3(e.number) : formatValueOfPick4(e.number)}
                  </td>
                  <td>{e.pick}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react';
import styles from './Tokenomics.module.css';
import TokenomicsImage from '../../Images/PhoneTokenomics.png';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const Tokenomics = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500,
      easing: 'ease-in-sine',
      once: false,
    });
  });
  return (
    <div className={`d-flex align-items-center ${styles.Tokenomics_mainDiv}`}>
      <div className={`container-fluid ${styles.Tokenomics_RowDiv}`}>
        <div className={`col-10 mx-auto `}>
          <div className={`row `}>
            <div
              data-aos="fade-right"
              className={`col-lg-6 order-lg-1 pt-5 d-flex justify-content-center ${styles.Tokenomics_Image}`}
            >
              <img src={TokenomicsImage} alt="" />
            </div>
            <div
              data-aos="fade-left"
              className={`col-lg-6 pt-4 pt-lg-0 order-lg-2 d-flex justify-content-center flex-column ${styles.Tokenomics_text}`}
            >
              <h1 className={`display-4 fw-bolder`}>Tokenomics</h1>

              <ul className={`text-white`}>
                <li>
                  <i class={`fas fa-star ${styles.Tokenomics_starLOGO}`}></i>
                  When exchanging for GS Tokens, 80% is used as collateral and locked in the Smart Contract.
                </li>
                <li>
                  <i class={`fas fa-star ${styles.Tokenomics_starLOGO}`}></i>
                  20% of the transaction is used to pay dividends to holders, fund staking pools, and run the business.
                </li>
                <li>
                  <i class={`fas fa-star ${styles.Tokenomics_starLOGO}`}></i>
                  GS token supply and price is regulated by the smart contract.  Price on exteral exchanges do not affect the contract price.
                </li>
                <li>
                  <i class={`fas fa-star ${styles.Tokenomics_starLOGO}`}></i>
                  Any wallet holding GS tokens can claim rewards in BNB, MATIC, and ETHEREUM at any time. There are no time locks.
                </li>
                <li>
                <i class={`fas fa-star ${styles.Tokenomics_starLOGO}`}></i>
                  Users can share their referral link to get paid direct commission on each transaction.
                </li>
                <li>
                <i class={`fas fa-star ${styles.Tokenomics_starLOGO}`}></i>
                  8% tax is applied when selling GS tokens and 92% of the collateral is transferred to the users wallet.  The GS tokens are then burned to control supply. 4% of the sale tax is used to pay rewards for holders and 4% is used to run the business.
                </li>
                <li>
                <i class={`fas fa-star ${styles.Tokenomics_starLOGO}`}></i>
                  There was no pre-sale of GS tokens as they can only be minted when depositing collateral in the contract.
                </li>
                <li>
                <i class={`fas fa-star ${styles.Tokenomics_starLOGO}`}></i>
                  Collateral is safe as no one but the account holder can withdraw from the contract.
                </li>
              </ul>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

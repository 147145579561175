import { formatEther } from "@ethersproject/units"
import { useWeb3React } from "@web3-react/core"
import React, { useState, useEffect, useMemo } from "react"
import { ImCross } from "react-icons/im"
import { useContract } from "../../../../Hooks"
import {
  useContractCommunityToken,
  useNftCommunityRewardPoolContract2,
} from "../../../../Hooks/nftPool"
import { fail, formatNumberInDecimals, getCommunityRewardContractAddress2 } from "../../../../utils"
import { Btn } from "../../../components";
import styles from "../index.module.css";
import tip from "../../../assets/images/tip.png";


export default function CommunityStakingPool() {
  const tokenContract = useContractCommunityToken()
  const nftContract = useNftCommunityRewardPoolContract2()
  const { account, chainId } = useWeb3React()

  const nftAddress = getCommunityRewardContractAddress2(chainId)

  const [totalStaked, setTotalStaked] = useState(0)
  const [currency, setCurrency] = useState("loading")
  const [address, setAddress] = useState("")
  const [isStaked, setIsStaked] = useState(false)
  const [id, setId] = useState("")

  const [TVL, setTVL] = useState(0)
  const [TRC, setTRC] = useState(0)
  const [rewards, setRewards] = useState(0)
  useMemo(() => {
    if (chainId === 80001 || chainId === 137) {
      setCurrency("MATIC")
    } else if (chainId === 56 || chainId === 97) {
      setCurrency("BNB")
    } else if (chainId === 43113 || chainId === 43114) {
      setCurrency("AVAX") 
    } else {
      setCurrency("ETH")
    }
  }, [chainId])

  async function fetchData() {
    if (tokenContract) {
      setTotalStaked((await tokenContract.balanceOf(nftAddress)).toString())
    }

    if (nftContract) {
      setTVL(formatNumberInDecimals(await nftContract.checkBalance()))
      setTRC(formatNumberInDecimals(await nftContract.totalClaimed()))
      setRewards(formatNumberInDecimals(await nftContract.claimAmount(account)))

      setIsStaked(await nftContract.isStaked(account))
    }
  }

  useEffect(async () => {
    fetchData()
  }, [tokenContract, nftContract])

  async function stake() {
    let balanceOf
    try {
      balanceOf = String(await tokenContract.balanceOf(account))
    } catch (error) {
      console.log(error)
    }

    if (Number(balanceOf)) {
      let isApproved = await tokenContract.isApprovedForAll(account, nftAddress)

      try {
        let tokenId = await tokenContract.tokenOfOwnerByIndex(account, 0)

        if (isApproved) {
          try {
            let tx = await nftContract.stake(tokenId)
            await tx.wait()
            fetchData()
          } catch (e) {
            if (e.code === 4001) return;
            let error = JSON.parse(JSON.stringify(e))
            fail(error.error.message)
          }
        } else {
          let tx = await tokenContract.setApprovalForAll(nftAddress, true)
          if (await tx.wait()) {
            let tx = await nftContract.stake(tokenId)
            await tx.wait()
            fetchData()
          }
        }
      } catch (e) {
        console.log(e)
        if (e.code === 4001) return;
        let error = JSON.parse(JSON.stringify(e));
         console.log("Error",error);
       fail("You already have an Community Key staked and cannot stake two from the same wallet.  Use the transfer feature and stake your token from a different wallet.")
      }
    } else fail("You do not own a Community Staking NFT. You must first buy a NFT then you can stake it here.")
  }

  async function unStake() {
    try {
      let tx = await nftContract.unStake()
      await tx.wait()
      fetchData()
    } catch (e) {
      if (e.code === 4001) return
      fail("you haven't stake")
    }
  }

  async function claimReward() {
    try {
      let tx = await nftContract.claimReward()
      await tx.wait()
      fetchData()
    } catch (e) {
      if (e.code === 4001) return
      fail("Stake To Claim")
    }
  }

  async function transfer() {
    let balanceOf
    try {
      balanceOf = String(await tokenContract.balanceOf(account))
    } catch (error) {
      console.log(error)
    }

    if (Number(balanceOf)) {
      try {
        let tx = await tokenContract.transferFrom(account, address, id)
        await tx.wait()
        fetchData()
      } catch (e) {
        if (e.code === 4001) return
        let error = JSON.parse(JSON.stringify(e))
        fail(error.error.message)
      }
    } else fail("You did not have Community key")
  }

  return (
    <>
      <h5>Community Pool Performance</h5>

      <div className={styles.access_key}>
        <div className={styles.access_content}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div className={styles.child_status}>
              <p>Tvl</p>
              <p>{TVL}</p>
              <p>{currency}</p>
              <div className={styles.img}>
                <img
                  src={tip}
                  alt="tip"
                  title="This is the Total Value currently locked in the Community Staking pool."
                />
              </div>
            </div>
            <div className={styles.child_status}>
              <p>Trc</p>
              <p>{TRC}</p>
              <p>{currency}</p>
              <div className={styles.img}>
                <img
                  src={tip}
                  alt="tip"
                  title="This is the Total Rewards Collected since the start of the Community Staking Pool."
                />
              </div>
            </div>
            <div className={styles.child_status}>
              <p>Total</p>
              <p>{totalStaked}</p>
              <p>Stakes</p>
              <div className={styles.img}>
                <img
                  src={tip}
                  alt="tip"
                  title="This is the total number of NFTs staked in the Community Pool."
                />
              </div>
            </div>
          </div>
          <div className={styles.status_staking}>
            <p>User Reward:</p>
            <p className={styles.amount}>
              {rewards} {currency}
            </p>
          </div>
        </div>

        <div className={styles.accessbtn}>
          <button onClick={stake}>STAKE</button>
          {isStaked && <button onClick={unStake}>UNSTAKE</button>}
          <Btn value={"CLAIM REWARD"} onClick={claimReward} />
          <button data-toggle="modal" data-target="#CommunityTokenModalCenter">
            TRANSFER NFT
          </button>
        </div>
      </div>

      <div
        className={`modal fade ${styles.modal_section}`}
        id="CommunityTokenModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className={`modal-dialog modal-dialog-centered`}>
          <div className={`modal-content ${styles.nested_modal}`}>
            <div className={styles.top_section}>
              <button type="button" class={`${styles.modal_btn}`} data-dismiss="modal">
                <ImCross />
              </button>
            </div>
            <div className="modal-body text-white " style={{ color: "black" }}>
              <h5 class={`${styles.modal_title} text-white pb-3`} id="exampleModalLabel">
                TRANSFER YOUR Community KEY
              </h5>
              <div className={` py-2 ${styles.modal_child}`}>
                <input
                  type="text"
                  placeholder="paste your destination wallet address here"
                  onChange={(e) => setAddress(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Token/NFT ID"
                  onChange={(e) => setId(e.target.value)}
                />
              </div>
              <div className={` py-2 ${styles.modal_child}`}>
                <Btn value={"Transfer"} onClick={transfer} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

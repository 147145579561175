import React, { useState, useEffect } from "react";
import style from "./index.module.css";
import logo from "../../assets/Icons/Group900.svg";
import { Btn } from "../../components";
import { GiHamburgerMenu } from "react-icons/gi";
import { MobSidebar } from "../mobsidebar";
// integration part
import { useWeb3React } from "@web3-react/core"
import {
  useContract,
} from "../../../Hooks";
import {
  useContractAccessKey,
  useNftRewardPoolContract,
  // hooks NFT Pool
  useContractCommunityToken,
  useNftCommunityRewardPoolContract2,
} from '../../../Hooks/nftPool';
import {
  formatNumberInDecimals,
  fromWei,
  getNftRewardPoolAddress,
 
  formatValue

} from "../../../utils";
import { NavLink } from "react-router-dom";








export const Header = (props) => {

  const { account, library, chainId, activate, error } = useWeb3React();

  // access Pool for NFT
  const contract = useContract();
  const tokenContract = useContractAccessKey();
  const nftContract = useNftRewardPoolContract();
  const nftAddress = getNftRewardPoolAddress(chainId)


  const [tokenPrice, setTokenPrice] = useState(0);
  const [refAddress, setRefAddress] = useState("loading");
  const [chainName, setChainName] = useState("Loading");
  const [totalETh, setTotalEth] = useState(0);
  const [totalEThCommunity, setTotalEthCommunity] = useState(0);
  const [totalReward, setTotalReward] = useState('Loading');
  const [totalStaked, setTotalStaked] = useState('Loading');
  const [ethPrice, setEthPrice] = useState(0);
  const [currency, setCurrency] = useState("");
  const [eth, setEth] = useState("loading");
  const [withdrawn, setWithdrawn] = useState("loading");




  //  community Pool State
const tokenContractCommunity = useContractCommunityToken();
const nftContractCommunity = useNftCommunityRewardPoolContract2();

  useEffect(async () => {
    if (tokenContract) {
      setTotalStaked((await tokenContract.balanceOf(nftAddress)).toString());
    }
    if (nftContract) {
      setTotalEth(formatNumberInDecimals(await nftContract.checkBalance()));
      setTotalReward(formatNumberInDecimals(await nftContract.totalClaimed()));
    }

    if (chainId === 80001 || chainId === 137) {
      setCurrency('MATIC');
    } else if (chainId === 56 || chainId === 97) {
      setCurrency('BNB');
    } else if (chainId === 43113 || chainId === 43114) {
      setCurrency('AVAX');
    } else {
      setCurrency('ETH');
    }
  }, [tokenContract, nftContract]);


// community Pool
useEffect(async () => {
  if (tokenContractCommunity) {
    // setTotalStaked((await tokenContractCommunity.balanceOf(nftAddress)).toString());
  }
  if (nftContractCommunity) {
    setTotalEthCommunity(formatNumberInDecimals(await nftContractCommunity.checkBalance()));
    // setTotalReward(formatNumberInDecimals(await nftContractCommunity.totalClaimed()));
  }

  if (chainId === 80001 || chainId === 137) {
    setCurrency('MATIC');
  } else if (chainId === 56 || chainId === 97) {
    setCurrency('BNB');
  } else if (chainId === 43113 || chainId === 43114) {
    setCurrency('AVAX');
  } else {
    setCurrency('ETH');
  }
}, [tokenContractCommunity, nftContractCommunity]);





  // added the clipbaord referlink
  useEffect(() => {
    let address;
    if (refAddress !== 'loading') {
      var url = document.URL;
      if (url.indexOf('?') !== -1) {
        if (refAddress === '0x0000000000000000000000000000000000000000') {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          address = urlParams.get('ref');
          setRefAddress(address);
        }
      } else {
        if (refAddress === '0x0000000000000000000000000000000000000000') {
          address = '0xfce153da366d5d65aa04d0a53f1110df9e074834';
          setRefAddress(address);
        }
      }
    }
  }, [library, contract, refAddress]);



  useEffect(async () => {
    if (contract) {
      let buyPrice = await contract.buyPrice();
      buyPrice = fromWei(+buyPrice.toString());
      setTokenPrice(buyPrice * ethPrice);
      console.log(`buy price ${tokenPrice}`);
      const refId = await contract.getReferrer();
      setRefAddress(refId);
      // switchChain();
      // console.log('library', library.provider);
    }
    if (chainId === 1) {
      setChainName("Ethereum");

    } else if (chainId === 56) {
      setChainName("Binance Smart Chain");

    } else if (chainId === 137) {
      setChainName("Avalanche Testnet");

    } else if (chainId === 43113) {
      setChainName("Avax Testnet");

    } else if (chainId === 43114) {
      setChainName("Avax");  

    } else if (chainId === 80001) {
      setChainName("Polygon Testnet");

    } else if (chainId === 97) {
      setChainName("Binance Testnet");

    } else if (chainId === undefined) {
      setChainName("Please Connect Wallet");
    } else {
      setChainName("Wrong chain.  Check network settings.");
    }
  }, [contract, ethPrice, chainId]);



  useEffect(async () => {
    console.log("library", library);
    if (contract) {


      let total_dep = await contract.totalDeposited();
      setEth(formatValue(total_dep));

      let total_withdraw = await contract.totalWithdraw();
      setWithdrawn(formatValue(total_withdraw));


      if (chainId === 80001 || chainId === 137) {
        // setLogoInCard(matic);
        setCurrency("MATIC");
      } else if (chainId === 56 || chainId === 97) {
        // setLogoInCard(binance);
        setCurrency("BNB");
      } else if (chainId === 43113 || chainId === 43114) {
        // setLogoInCard(avax);
        setCurrency("AVAX");
      } else {
        // setLogoInCard(ethereum);
        setCurrency("ETH");
      }
    }
  }, [contract, library]);







  // console.log(props);
  // const copyToClipboard = () => {
  //   const el = document.createElement("textarea");
  //   el.value = `http://the8020.org/dashboard?ref=${props.refer}`;
  //   document.body.appendChild(el);
  //   el.style.textTransform = "lowercase";
  //   el.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(el);
  //   // alert("Copied the Ref.link");
  // };
  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.style.textTransform="lowercase";
    el.value = `http://the8020.org/dashboard?ref=${account}`;
    // let valueone=el.value.toLocaleLowerCase();
    
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  const [isOpen, setIsopen] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  return (
    <>
      <div className={style.updated_header}>
        <div className={style.nested_header}>
          <div className={style.img_header}>
            <NavLink to="/">
            <img src={logo} alt="8020-logo" width={"140px"} />
            </NavLink>
          </div>
          {/* <div className={style.referel_section}>
            {refAddress && (
              <a className="nav-link" style={{ color: "white" }}>
                {`Your Referral : ${refAddress.substring(
                  0,
                  6
                )}...${refAddress.substring(refAddress.length - 4)} `}
              </a>
            )}
            <div className={style.copylink} onClick={copyToClipboard}>
              <button
                type="button"
                // class="btn btn-primary"
                className={style.btn_headerCopy}
                data-toggle="modal"
                data-target="#exampleModalCenters"
              >
                Copy Ref.Link
              </button>
            </div>
          </div> */}
          <div className={style.boxes_header}>
            <div className={style.nested_box}>
              <p>
                Total Deposited -{" "}
                {eth !== "loading" ? eth : 0}{" "}
                {currency}
              </p>
              <p>
                Total Withdrawn -{" "}
                {withdrawn !== "loading" ? withdrawn : 0}{" "}
                {currency}
              </p>
            </div>
            <div className={style.nested_box}>
              <p>
                Access Pool - {totalETh && totalETh}{" "}
                {currency && currency}
              </p>
              <p>
                Community Pool -{" "}
                {totalEThCommunity && totalEThCommunity}{" "}
                {currency && currency}
              </p>
            </div>
          </div>
          <div
            className={`btn-primary ${style.showButton}`}
            onClick={ToggleSidebar}
          >
            {/* <i className="fa fa-bars"></i> */}
            <GiHamburgerMenu />
          </div>
        </div>
      </div>
      {/* Modal */}
      <div
        className={`modal fade ${style.modal_section}`}
        id="exampleModalCenters"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className={`modal-dialog modal-dialog-centered`}>
          <div className={`modal-content ${style.nested_modal}`}>
            <div className="modal-body text-white " style={{ color: "black" }}>
              <div className={style.desc}>
                Your link is copied on the clipboard.
              </div>
              <div
                className={` py-2 ${style.modal_child}`}
                data-dismiss="modal"
              >
                {/* <p className="h5 text-center">{value.maticdec}</p> */}
                <Btn value={"ok"} type="button" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <MobSidebar ToggleSidebar={ToggleSidebar} isOpen={isOpen} eth={eth} currency={currency} withdrawn={withdrawn} totalETh={totalETh} totalEThCommunity={totalEThCommunity} />
    </>
  );
};

import React, { useState, useEffect } from "react"
import styles from "./index.module.css"
import { Header, Sidebar, Footer } from "../../layout"
import advertistment from "../../assets/images/AdBanner2.png"

// integration part
import { useWeb3React } from "@web3-react/core"
import { formatNumberInDecimals, getNftRewardPoolAddress } from "../../../utils"
import { useContract } from "../../../Hooks"
import { injected } from "../../../connectors"
import { useContractAccessKey, useNftRewardPoolContract } from "../../../Hooks/nftPool"
import { formatEther } from "@ethersproject/units"
import AccessStakingPool from "./AccessStakingPool/AccessStakingPool"
import CommunityStakingPool from "./CommunityStakingPool/CommunityStakingPool"
import AccessNFT from "./AccessNFT/AccessNFT"
import CommunityNFT from "./CommunityNFT/CommunityNFT"

export const StakingPool = () => {
  const { account, library, chainId, activate, error } = useWeb3React()
  const contract = useContract()

  const [tokenBalance, setTokenBalance] = useState("loading")

  //  Integration part
  useEffect(async () => {
    if (contract) {
      let tokenBal = await contract.balanceOf(account)
      setTokenBalance(formatNumberInDecimals(tokenBal))
    }
  }, [library])

  const nftContract = useNftRewardPoolContract()
  const tokenContract = useContractAccessKey()
  const nftAddress = getNftRewardPoolAddress(chainId)

  const [currency, setCurrency] = useState("loading")

  useEffect(async () => {
    if (nftContract) {
      if (chainId === 80001 || chainId === 137) {
        setCurrency("MATIC")
      } else if (chainId === 56 || chainId === 97) {
        setCurrency("BNB")
      } else if (chainId === 43113 || chainId === 43113) {
        setCurrency("AVAX")
      } else {
        setCurrency("ETH")
      }
    }
  }, [nftContract])

  const [balance, setBalance] = useState("")

  useEffect(() => {
    if (library) {
      async function runGetBalance() {
        let getBalance = await library.getBalance(account)
        getBalance = formatEther(getBalance.toString())
        getBalance = Number(getBalance).toFixed(2)
        setBalance(getBalance)
      }

      runGetBalance()
    }
  }, [library])

  async function stake() {
    let isApproved
    try {
      isApproved = await tokenContract.isApprovedForAll(account, nftAddress)
    } catch (error) {
      console.log(error)
    }

    let tokenId
    try {
      tokenId = await tokenContract.tokenOfOwnerByIndex(account, 0)
    } catch (error) {
      console.log(error)
    }

    if (isApproved) {
      try {
        nftContract.stake(tokenId)
      } catch (e) {
        console.log(e.reason)
      }
    } else {
      try {
        let tx = await tokenContract.setApprovalForAll(nftAddress, true)
        await tx.wait()
      } catch (error) {
        console.log(error)
      }

      try {
        await nftContract.stake(tokenId)
      } catch (error) {
        console.log(error)
      }
    }
  }

  async function unStake() {
    try {
      await nftContract.unStake()
    } catch (e) {
      console.log(e)
      // fail(error.error.message)
    }
  }

  async function claimReward() {
    try {
      await nftContract.claimReward()
    } catch (error) {
      console.log(error)
    }
  }

  const [chainName, setChainName] = useState("Loading")

  useEffect(async () => {
    if (chainId === 1) {
      setChainName("Ethereum")
    } else if (chainId === 4) {
      setChainName("Rinkeby Testnet")
    } else if (chainId === 56) {
      setChainName("Binance Chain")
    } else if (chainId === 137) {
      setChainName("Polygon")
    } else if (chainId === 80001) {
      setChainName("Polygon Testnet")
    } else if (chainId === 97) {
      setChainName("Binance Testnet")
    } else if (chainId === 43113) {
      // setLogoInCard(ethereum);
      setChainName("AVAX TEST");
    } else if (chainId === 43114) {
      setChainName("AVAX");
    } else if (chainId === 8453) {
      setChainName("BASE Network")
    } else if (chainId === undefined) {
      setChainName("Please Connect Wallet")
    } else {
      setChainName("Wrong chain.  Check network settings.")
    }
  }, [chainId])

  return (
    <>
      <div className={styles.main_stakingpool}>
        <div className={styles.nested_satkingpool}>
          <Header />

          <div className={styles.content_stakingpool}>
            {/* sidebar div */}
            <div className={styles.sidebar_div}>
              <Sidebar />
            </div>
            <div className={styles.stakingpool_data}>
              <div className={styles.nested_stakingpool}>
                <div className={styles.network_tab}>
                  <div className={styles.network_heading}>Connected - {chainName}</div>
                  <div
                    onClick={(e) => {
                      e.preventDefault()
                      activate(injected)
                    }}
                    className={`${styles.network_heading} ${styles.button_section}`}
                  >
                    {account === undefined
                      ? "Connect Wallet"
                      : account === null
                      ? "None"
                      : `Wallet ${account.substring(0, 6)}...${account.substring(
                          account.length - 4
                        )}`}
                  </div>
                </div>
                <AccessStakingPool />

                {/* line */}
                <hr style={{margin: '8px 0px'}} />
                <CommunityStakingPool />
                {/* <hr /> */}
                <div className={styles.wallet_balance}>
                  <p>Tokens in Your Wallet</p>
                  <p>{tokenBalance} - GS50</p>
                  <p>
                    {balance} - {currency}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.side_data2}>
              <div className={styles.netsed_sideData}>
                <AccessNFT />
                <hr />
                <CommunityNFT />
              </div>
            </div>
            <div className={styles.advertisment_data}>
              <img src={advertistment} alt="advertistment" width={"100%"} />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"
import style from "./index.module.css"
import { Header, Sidebar, Footer } from "../../layout"
import { Btn } from "../../components"
import tip from "../../assets/images/tip.png"
import { LotteryFrom1 } from "./lottertable"
import { LotteryFrom2 } from "./lotterytable2"
import {
  countAddress,
  formatValue,
  formatValueOfPick3,
  formatValueOfPick4,
  getLotteryContractaddress,
  toWei,
  fromWei,
} from "../../../utils"
import { injected } from "../../../connectors";

import { useContract, useTokenContract } from "../../../Hooks/lottery"
import { useWeb3React } from "@web3-react/core"
import { LotteryFrom3 } from "./lotterytable3"
import advertistment from "../../assets/images/AdBanner3.png"

export const DropLottery = () => {
  const { account, chainId,activate } = useWeb3React()
  console.log(account, chainId)

  const contract = useContract()
  const tokenContract = useTokenContract()
  const tokenAdress = getLotteryContractaddress(chainId)

  const [pick3TicketsDiv, setPick3TicketsDiv] = useState([])
  const [pick4TicketsDiv, setPick4TicketsDiv] = useState([])

  const [tokenAllowance, setTokenAllowance] = useState(0)

  const [pick3Number, setPick3Number] = useState("0 0 0")
  const [pick4Number, setPick4Number] = useState("0 0 0 0")

  const [pick3GS50, setPick3GS50] = useState(0)
  const [pick4GS50, setPick4GS50] = useState(0)
  const [pick3Wallet, setpick3Wallet] = useState(0)
  const [pick4Wallet, setpick4Wallet] = useState(0)
  const [pick3Limit, setPick3Limit] = useState(0)
  const [pick4Limit, setPick4Limit] = useState(0)
  const [chainName, setChainName] = useState("Loading");


  const [pick3UserLotteryNumber, setPick3UserLotteryNumber] = useState([])
  const [pick4UserLotteryNumber, setPick4UserLotteryNumber] = useState([])

  useEffect(async () => {
    if (!!contract) {
      setPick3UserLotteryNumber(
        (await contract.getUserLotteryNumber(account, 3)).map((e) => {
          return String(e)
        })
      )

      setPick4UserLotteryNumber(
        (await contract.getUserLotteryNumber(account, 4)).map((e) => {
          return String(e)
        })
      )
    }
  }, [contract])

  useEffect(async () => {
    if (contract) {
      setPick3GS50(formatValue(await contract.pick3Pot()))
      setPick4GS50(formatValue(await contract.pick4Pot()))

      let maxLimit = await contract.maxNoOfLottery()
      setPick4Limit(+maxLimit - +(await contract.currentIdForPick4()))
      setPick3Limit(+maxLimit - +(await contract.currentIdForPick3()))

      let pick3Events = await contract.queryFilter(contract.filters.Pick3LotteryBought())
      let pick3Addresses = []
      pick3Events.filter(({ args }) => {
        if (args.UserAddress) {
          pick3Addresses.push(args.UserAddress)
        }
        return true
      })

      setpick3Wallet(countAddress(pick3Addresses))

      let pick4Addresses = []

      let pick4Events = await contract.queryFilter(contract.filters.Pick4LotteryBought())
      pick4Events.filter(({ args }) => {
        if (args.UserAddress) {
          pick4Addresses.push(args.UserAddress)
        }
        return true
      })
      setpick4Wallet(countAddress(pick4Addresses))
    }
  }, [contract])

  useEffect(async () => {
    if (!!contract) {
      let pick3Numbers = []
      let pick4Numbers = []
      const pick3Events = await contract.queryFilter(contract.filters.Random())
      pick3Events.map(({ args }) => {
        if (Number(String(args.pick)) === 3) {
          pick3Numbers.push(String(args.number))
        } else if (Number(String(args.pick)) === 4) {
          pick4Numbers.push(String(args.number))
        }
        return true
      })
      setPick3Number(pick3Numbers[pick3Numbers.length - 1])
      setPick4Number(pick4Numbers[pick4Numbers.length - 1])
    }
  }, [contract])

  function getValuesOfPick3() {
    const n1 = document.querySelector("#pick3N1")
    const n2 = document.querySelector("#pick3N2")
    const n3 = document.querySelector("#pick3N3")
    const finalNum = n1.value + n2.value + n3.value

    if (pick3TicketsDiv.includes(finalNum)) {
      alert("ERROR: you pick same Number")
      return false
    }

    if (pick3TicketsDiv.length < 5 && finalNum >= 0) {
      setPick3TicketsDiv((prev) => [...prev, finalNum])
    }

    n1.selectedIndex = 0
    n2.selectedIndex = 0
    n3.selectedIndex = 0
  }

  function getValuesOfPick4() {
    const n1 = document.querySelector("#pick4N1")
    const n2 = document.querySelector("#pick4N2")
    const n3 = document.querySelector("#pick4N3")
    const n4 = document.querySelector("#pick4N4")
    const finalNum = n1.value + n2.value + n3.value + n4.value

    if (pick4TicketsDiv.includes(finalNum)) {
      alert("ERROR: you pick same Number")
      return false
    }

    if (pick3TicketsDiv.length < 5 && finalNum >= 0) {
      setPick4TicketsDiv((prev) => [...prev, finalNum])
    }

    n1.selectedIndex = 0
    n2.selectedIndex = 0
    n3.selectedIndex = 0
    n4.selectedIndex = 0
  }

  useEffect(async () => {
    if (!!tokenContract) {
      setTokenAllowance(String(await tokenContract.allowance(account, tokenAdress)))
    }
  }, [tokenContract])

  const handleRemoveItem = (idx, pick) => {
    let temp

    if (pick === 3) {
      temp = [...pick3TicketsDiv]
    } else if (pick === 4) {
      temp = [...pick4TicketsDiv]
    }

    temp.splice(idx, 1)

    if (pick === 3) {
      setPick3TicketsDiv(temp)
    } else if (pick === 4) {
      setPick4TicketsDiv(temp)
    }
  }

  async function buyLotteryForPick3() {
    const pick3Price = toWei("5")
    const amount = String(pick3Price * pick3TicketsDiv.length)

    if (tokenAllowance >= Number(amount)) {
      try {
        const tx = await contract.buyLotteryForPick3(pick3TicketsDiv, amount)
        await tx.wait()
        window.location.reload()
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        const tx = await tokenContract.approve(tokenAdress, toWei("10000000000000"))
        await tx.wait()
      } catch (error) {
        console.error(error)
      }

      try {
        const tx = await contract.buyLotteryForPick3(pick3TicketsDiv, amount)
        await tx.wait()
        window.location.reload()
      } catch (error) {
        console.error(error)
      }
    }
  }

  async function buyLotteryForPick4() {
    const pick4Price = toWei("15")
    const amount = String(pick4Price * pick4TicketsDiv.length)

    if (tokenAllowance >= Number(amount)) {
      try {
        const tx = await contract.buyLotteryForPick4(pick4TicketsDiv, amount)
        await tx.wait()
        window.location.reload()
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        let tx = await tokenContract.approve(tokenAdress, toWei("1000000000"))
        await tx.wait()
      } catch (error) {
        console.error(error)
      }

      try {
        const tx = await contract.buyLotteryForPick4(pick4TicketsDiv, amount)
        if (await tx.wait()) window.location.reload()
      } catch (error) {
        console.error(error)
      }
    }
  }



  useEffect(async () => {
    if (chainId === 1) {
      setChainName("Ethereum")
    } else if (chainId === 4) {
      setChainName("Rinkeby Testnet")
    } else if (chainId === 56) {
      setChainName("Binance Smart Chain")
    } else if (chainId === 137) {
      setChainName("Polygon")
    } else if (chainId === 80001) {
      setChainName("Polygon Testnet")
    } else if (chainId === 97) {
      setChainName("Binance Testnet")
    } else if (chainId === undefined) {
      setChainName("Please Connect Wallet")
    } else {
      setChainName("Wrong chain.  Check network settings.")
    }
  }, [chainId])



  return (
    <div className={style.main_droplottery}>
      <div className={style.nested_droplottery}>
        <Header />

        <div className={style.content_droplottery}>
          {/* sidebar div */}
          <div className={style.lottry_sidebar_div}>
            <Sidebar />
          </div>

          <div className={style.droplottery_data}>
            <div className={style.nested_dataDroplottery}>
            <div className={style.network_tab}>
                <div className={style.network_heading}>Network - {chainName}</div>
                <div
                  onClick={(e) => {
                    e.preventDefault()
                    activate(injected)
                  }}
                  className={`${style.network_heading} ${style.button_section}`}
                >
                  {" "}
                  {account === undefined
                    ? "Connect Wallet"
                    : account === null
                    ? "None"
                    : `Wallet ${account.substring(0, 6)}...${account.substring(
                        account.length - 4
                      )}`}
                </div>
              </div>
              {/* <div className={style.network_droplottery}>
                <div className={style.network_droplotteryheading}>
                  Network - Binance
                </div>
                <div className={style.network_droplotteryheading}>
                  Wallet - 0x123***555
                </div>
              </div> */}
              {/* drop 3 and drop 4 */}
              <div className={style.drop_box}>
                <div className={style.pick3_drop}>
                  <p className={style.drop_heading}>Pick 3 Drop</p>
                  <p className={style.drop_para}>Total Active Picks</p>
                  <p className={style.drop_para}>{pick3UserLotteryNumber.length}</p>
                  <p className={style.drop_para}>Your Active Number</p>
                  {/* {pick3UserLotteryNumber.map((e) => {
                    return (
                      <div className="col mx-auto">
                        <div
                          style={{ color: "black" }}
                          className={`card p-2 m-1 fw-bolder fs-4 ${style.nestedDiv_LotteryNumber}`}
                        >
                          {e}
                        </div>
                      </div>
                    )
                  })} */}
            <div
            class={`row col-10 col-md-10 mx-auto mb-4  ${style.user_pick_lottery_numbers}`}
          >
            {pick3UserLotteryNumber.map((e) => {
              return (
                <div class="col mx-auto">
                  <div
                    class={`card p-2 m-1 text-white fw-bolder fs-4 ${style.nestedDiv_LotteryNumber}`}
                  >
                    {e}
                  </div>
                </div>
              );
            })}
          </div>

                  <div className={style.img}>
                    <img src={tip} alt="tip" />
                    <span className={`${style.toolTipText}`}>
                      Here you will see your connected wallet and your active picks for the current
                      drawing. Once the drawing takes place all tickets become used and numbers are
                      cleared. If you win the drawing you will be notified the next time you connect
                      to the site.
                    </span>
                  </div>
                </div>
                <div className={style.pick4_drop}>
                  <p className={style.drop_heading}>Pick 4 Drop</p>
                  <p className={style.drop_para}>Total Active Picks</p>
                  <p className={style.drop_para}>{pick4UserLotteryNumber.length}</p>
                  <p className={style.drop_para}>Your Active Number</p>
                  {/* {pick4UserLotteryNumber.map((e) => {
                    return (
                      <div className="col mx-auto">
                        <div
                          style={{ color: "black" }}
                          className={`card p-2 m-1 fw-bolder fs-4 ${style.nestedDiv_LotteryNumber}`}
                        >
                          {e}
                        </div>
                      </div>
                    )
                  })} */}

          <div
            class={`row col-10 col-md-10 mx-auto mb-4 user-pick-lottery-numbers ${style.user_pick_lottery_numbers}`}
          >
            {pick4UserLotteryNumber.map((e) => {
              return (
                <div class="col mx-auto">
                  <div
                    class={`card p-2 m-1 text-white fw-bolder fs-4 ${style.nestedDiv_LotteryNumber}`}
                  >
                    {e}
                  </div>
                </div>
              );
            })}
          </div>

                  <div className={style.img}>
                    <img src={tip} alt="tip" />
                    <span className={`${style.toolTipText}`}>
                      Here you will see your connected wallet and your active picks for the current
                      drawing. Once the drawing takes place all tickets become used and numbers are
                      cleared. If you win the drawing you will be notified the next time you connect
                      to the site.
                    </span>
                  </div>
                </div>
              </div>
              {/* End Drop 3 and 4 */}

              {/* stattistics */}
              <div className={style.statistics}>
                <h4>Statictics</h4>
                <div className={style.statistics_box}>
                  <div className={style.statistics_nestedbox}>
                    <h5>{pick3GS50} GS50</h5>
                    <p>
                      GS50 IN PICK 3 (NEXT DRAWING WILL RUN AFTER {pick3Limit} WALLETS ENTERED THE
                      CONTEST)
                    </p>
                    <div className={style.img}>
                      <img src={tip} alt="tip" />
                      <span className={`${style.toolTipText}`}>
                        This is the total amount of GS50 in the Pick 3 Drop. The drawing will start
                        once the required number of unique wallets enter the contest. You can only
                        make three picks per ticket order and can have up to 20 tickets per drawing.
                      </span>
                    </div>
                  </div>
                  <div className={style.statistics_nestedbox}>
                    <h5>{pick4GS50} GS50</h5>
                    <p>
                      GS50 IN PICK 4 (NEXT DRAWING WILL RUN AFTER {pick4Limit} WALLETS ENTERED THE
                      CONTEST)
                    </p>
                    <div className={style.img}>
                      <img src={tip} alt="tip" />
                      <span className={`${style.toolTipText}`}>
                        This is the total amount of GS50 in the Pick 3 Drop. The drawing will start
                        once the required number of unique wallets enter the contest. You can only
                        make three picks per ticket order and can have up to 20 tickets per drawing.
                      </span>
                    </div>
                  </div>

                  <div className={style.statistics_nestedbox}>
                    <h5>{(+pick3GS50 + +pick4GS50).toFixed(2)} GS50</h5>
                    <p>TOTAL GS50 BEING DROPPED.</p>
                    <div className={style.img}>
                      <img src={tip} alt="tip" />
                      <span className={`${style.toolTipText}`}>
                        This is the total amount of GS50 in the Pick 3 Drop. The drawing will start
                        once the required number of unique wallets enter the contest. You can only
                        make three picks per ticket order and can have up to 20 tickets per drawing.
                      </span>
                    </div>
                  </div>
                  <div className={`${style.statistics_nestedbox} ${style.statistics_nesteboxlast}`}>
                    <div>
                      <h5>{pick3Wallet} Wallets</h5>
                      <p>Total Pick 3 Players</p>
                    </div>
                    <div>
                      <h5>{pick4Wallet} Wallets</h5>
                      <p>Total Pick 4 Players</p>
                    </div>
                    <div className={style.img}>
                      <img src={tip} alt="tip" />
                      <span className={`${style.toolTipText}`}>
                        This is the total amount of GS50 in the Pick 3 Drop. The drawing will start
                        once the required number of unique wallets enter the contest. You can only
                        make three picks per ticket order and can have up to 20 tickets per drawing.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Play Lottery */}
              <div className={style.playlottery} style={{ marginTop: "24px" }}>
                <div className={style.playlottery_box}>
                  <div className={style.playlottery_nestedbox}>
                    <h5>Pick 3 Numbers</h5>

                    <div className={style.playlotteryselection_div}>
                      <select
                        className={style.select_div}
                        id="pick3N1"
                        aria-label="Default select example"
                      >
                        <option selected value="select"></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                      <select
                        className={style.select_div}
                        id="pick3N2"
                        aria-label="Default select example"
                      >
                        <option selected value="select"></option>

                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                      <select
                        className={style.select_div}
                        id="pick3N3"
                        aria-label="Default select example"
                      >
                        <option selected value="select"></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                      <div className={style.btnplus}>
                        <Btn onClick={getValuesOfPick3} value={"+"} />
                      </div>
                    </div>

                    <p>NUMBERS ON THIS TICKET:</p>
                    {pick3TicketsDiv.map((e, index) => {
                      return (
                        <>
                          <div
                            className={("list-group", style.your_pick_box)}
                            id="pick4Box"
                            key={index}
                            onClick={() => {
                              handleRemoveItem(index, 3)
                            }}
                          >
                            {e}
                          </div>
                        </>
                      )
                    })}
                    <Btn value={"Play Lottery"} onClick={buyLotteryForPick3} />
                    <div className={style.img}>
                      <img src={tip} alt="tip" />
                      <span className={`${style.toolTipText}`}>
                        Use the dropdown to select 4 numbers between 0-9. You can have up to 3
                        entries per ticket order.
                      </span>
                    </div>
                  </div>
                  <div className={style.playlottery_nestedbox}>
                    <h5>Pick 4 Numbers</h5>

                    <div className={style.playlotteryselection_div}>
                      <select
                        className={style.select_div}
                        id="pick4N1"
                        aria-label="Default select example"
                      >
                        <option selected value="select"></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                      <select
                        className={style.select_div}
                        id="pick4N2"
                        aria-label="Default select example"
                      >
                        <option selected value="select"></option>

                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                      <select
                        className={style.select_div}
                        id="pick4N3"
                        aria-label="Default select example"
                      >
                        <option selected value="select"></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                      <select
                        className={style.select_div}
                        id="pick4N4"
                        aria-label="Default select example"
                      >
                        <option selected value="select"></option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                      <div className={style.btnplus}>
                        <Btn onClick={getValuesOfPick4} value={"+"} />
                      </div>
                    </div>

                    <p>NUMBERS ON THIS TICKET:</p>
                    {pick4TicketsDiv.map((e, index) => {
                      return (
                        <>
                          <div
                            className={("list-group", style.your_pick_box)}
                            key={index}
                            onClick={() => {
                              handleRemoveItem(index, 4)
                            }}
                          >
                            {e}
                          </div>
                        </>
                      )
                    })}
                    <Btn onClick={buyLotteryForPick4} value={"Play Lottery"} />

                    <div className={style.img}>
                      <img src={tip} alt="tip" />
                      <span className={`${style.toolTipText}`}>
                        Use the dropdown to select 4 numbers between 0-9. You can have up to 4
                        entries per ticket order.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Lottery */}

              {/* List of Number */}
              <div className={style.listNumber} style={{ marginTop: "24px" }}>
                <div className={style.listNumber_box}>
                  <div className={style.listNumber_nestedbox}>
                    <h5>Last Pick 3 Numbers</h5>
                    <div className={style.list_counting}>
                      <p className={style.nub_bottomTwoBox}>{formatValueOfPick3(pick3Number)}</p>
                    </div>
                    <div className={style.img}>
                      <img src={tip} alt="tip" />
                      <span className={`${style.toolTipText}`}>
                        This is the last number that was randomly generated in the Pick 3 drawing.
                        You can only win by matching the numbers exactly.
                      </span>
                    </div>
                  </div>
                  <div className={style.listNumber_nestedbox}>
                    <h5>Last Pick 4 Numbers</h5>
                    <div className={style.list_counting}>
                      <p className={style.nub_bottomTwoBox}>{formatValueOfPick4(pick4Number)}</p>
                    </div>
                    <div className={style.img}>
                      <img src={tip} alt="tip" />
                      <span className={`${style.toolTipText}`}>
                        This is the last number that was randomly generated in the Pick 4 drawing.
                        You can only win by matching the numbers exactly.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Table Section */}
              <LotteryFrom1 />
              <LotteryFrom2 />
              <LotteryFrom3 />
            </div>
          </div>
          {/* advertsiment space */}

          <div className={style.advertistment_data}>
              <img src={advertistment} alt="advertistment" width={"100%"} />
            </div>
          {/*  End Center part */}
        </div>

        <Footer />
      </div>
    </div>
  )
}

import React, { useEffect } from 'react';
import styles from './NFTRewardPool.module.css';
import NFTrewardpool from '../../Images/NFTRewardPool.svg';
import Aos from 'aos';
import 'aos/dist/aos.css';

export const NFTRewardPool = () => {
  useEffect(() => {
    Aos.init({
      duration: 1400,
      once: false,
      easing: 'ease-in-sine',
    });
  });
  return (
    <div className={`d-flex align-item-center ${styles.NFTRewardPool_mainDiv}`}>
      <div className={`container-fluid`}>
        {/* <div className={`row`}> */}
        <div className={`col-10 mx-auto  ${styles.NFTRewardPool}`}>
          <div className={`row`}>
            <div
              data-aos="fade-right"
              className={`col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column ${styles.NFTRewardPool_FirstDiv}`}
            >
              <h1 className={`display-5 fw-bolder`}>NFT Reward Pool</h1>
              <p className={`px-4 py-3 ${styles.NFTRewardPool_para}`}>
                <span className={`fw-light`}>
                  The Access Key and Community staking pools collect up to 7% of NFT sales from the NFT Launchpad. Holders can stake them to receive rewards as people buy NFTs. There are only 500 Access Key NFTs and 5,000 Community NFTs available for minting on Polygon or Binance Smart Chain.  Only 1 NFT per wallet can be staked, but you can buy multiple NFTs and transfer them to new wallets.  As more utility products are added to the ecosystem, they will also share with the staking pools.
                </span>
              </p>
            </div>
            <div
              className={`col-lg-6 order-1 order-lg-2 ${styles.NFTRewardPool_Image}`}
              data-aos="fade-left"
            >
              <img src={NFTrewardpool} alt="" />
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};
